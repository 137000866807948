<template>
  <div class="container">
    <div
      v-if="!rowPinned"
      class="data"
    >
      <div
        v-for="value in values()"
        :key="value.code"
      >
        {{ value.name }} ({{ value.code }}) <span
          style="cursor:pointer"
          @mousedown="deleteEmp(value.id)"
        > X </span>
      </div>
    </div>
    <div class="select-box">
      <v-select
        v-model="selected"
        multiple
        :placeholder="placeHolder"
        :options="docpLabels"
        :items="docpLabels"
        style="height: 20px; width: 100%; ;"
        @mousedown="onMouseDown"
        @keydown="onKeyDown"
        @input="searchDocp"
      >
        <template #no-options="{ search, searching }">
          <em
            v-if="searching && loading"
            style="opacity: 0.5"
          >
            searching for {{ search }}
          </em>
          <em v-else />
        </template>
        <template #spinner>
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm text-info"
            role="status"
            aria-hidden="true"
          />
        </template>
      </v-select>
    </div>
    <div
      v-if="rowPinned"
      class="data"
    >
      <div
        v-for="value in values()"
        :key="value.code"
      >
        {{ value.name }} ({{ value.code }}) <span
          style="cursor:pointer"
          @mousedown="deleteEmp(value.id)"
        > X </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useStore } from 'vuex';
import makeFilter from '../composables/makeFilter';
import postData from '../composables/postData';
import getFatRow from '../composables/fatRowGenerator.js';
import getFolders from '../composables/getFolders';

export default {
  components: { vSelect },
  props: ['params'],
  setup(props) {
    const rowPinned = props.params.node.rowPinned == 'bottom';
    const store = useStore();
    const globalBaseData = store.state.dutyCharts;
    const bottomRowsData = store.state.dutyChartPinnedBottomRows;
    // const globalSet = JSON.parse(JSON.stringify(store.state.selectedEmps));
    const selected = ref([]);
    const options = ref([]);
    const searchTerm = ref('');
    let filterParams = reactive([]);
    const loading = ref(false)
    const bSettings = ref(store.getters['bSettings'])
    const { getAllFoldersList, getCurrentFolder } = getFolders();

    const placeHolder = computed(() => 'Search employees');
    const docpLabels = computed(() => {
      const weeklyOff = bottomRowsData[0][props.params.colDef.field]?.employees || [];
      const leaves = bottomRowsData[1][props.params.colDef.field]?.employees || [];
      const empOnLeave = [...weeklyOff, ...leaves];
      const ret = options.value.filter((o) => empOnLeave.findIndex((eol) => eol.code == o.code) < 0).map((r) => {
        if (r.label == null) {
          r.label = `${r.name} (${r.code})`;
        }
        return r;
      });
      return ret;
    });

    function markSelectedOption(option) {
      return selected.value.some((item) => item.code === option.code); // Check if option is selected
    }

    function convertToUTC(dateString) {
      // Define an object for month name mapping
      const months = {
        jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
        jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
      };
      // Split the string by underscores
      const [_, day, month, year] = dateString.toLowerCase().split('_');

      // Convert day and year to integers
      const dayOfMonth = parseInt(day, 10);
      const monthIndex = months[month];
      const yearNumber = parseInt(year, 10);

      // Create a Date object in UTC
      const utcDate = new Date(yearNumber, monthIndex, dayOfMonth).toISOString();
      return utcDate;
    }

    async function getDocpickerList() {
      try {
        if (Object.keys(filterParams).length !== 0) {
          const folderList = getAllFoldersList(bSettings.value);
          const currentDocpFolder = getCurrentFolder('employees', folderList);
          const { listData } = postData();
          const txnParams = { refRows: true };
          txnParams.filters = filterParams;
          txnParams.projections = {
            'employees': ['id', 'deleted', 'code', 'persons_id', 'designation', 'departments'],
            'employees.departments':['id'],
            'employees.designation':['id'],
            'employees.persons_id':['id', 'first_name', 'last_name', 'middle_name']
          }
          const list = await listData(currentDocpFolder, txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          }
          else {
            if (list.output?.data?.records?.length > 0) {
              const { fatRowGenerator } = getFatRow();
              const fatRows = fatRowGenerator(list);
              options.value = fatRows.map((item) => ({ id: item.id, name: item['persons_id'][0]['first_name']['eng'], code: item.code }));
            }
          }
          loading.value = false;
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    onMounted(async() => {
      await nextTick();
      await getDocpickerList();
      const multiselect = document.getElementsByClassName('vs__search');
      if (multiselect.length > 0) {
        multiselect[multiselect.length - 1].focus();
      }
    });

    async function searchDocp(event) {
      try {
        searchTerm.value = event.target.value;
        if (searchTerm.value.length >= 2) {
          const filterFields = [
            { key:'code' },
            { key:'first_name' },
            { key:'last_name' },
            { key:'middle_name' },
          ];

          const folderList = getAllFoldersList(bSettings.value);
          const employeesFolder = getCurrentFolder('employees', folderList);
          const { generateFilterParams } = makeFilter(
            searchTerm.value,
            employeesFolder,
            filterFields
          );
          filterParams = generateFilterParams();
          const env = bSettings.value.env;
          if (env.code !== 'admin') {
            filterParams = {filterInput: [{
              operator: 'in',
              path: 'employees.subsections.duty_chart_officer.id',
              value: [env.id],
            }], joinop: 'and'}
          }
          loading.value = true;
          await getDocpickerList();
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    function onMouseDown(event) {
      // Keep the focus on the grid cell after the dropdown closes
      if (event.target.textContent != '') {
        setTimeout(() => {
          const cellElement = props.params.eGridCell;
          if (cellElement) {
            cellElement.focus();
          }
        }, 200);
      }
    }

    function deleteEmp(emp_id) {
      if (props.params.node.rowPinned == 'bottom') {
        const fIndex = bottomRowsData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1) {
          const emps = bottomRowsData[fIndex][props.params.colDef.field].employees || [];
          const empIndex = emps.findIndex((gd) => gd.id == emp_id);
          if (empIndex > -1) {
            if (bottomRowsData[fIndex][props.params.colDef.field].employees[empIndex].changed == null || bottomRowsData[fIndex][props.params.colDef.field].employees[empIndex].changed == false) {
              bottomRowsData[fIndex][props.params.colDef.field].employees[empIndex].toRemove = true;
              bottomRowsData[fIndex][props.params.colDef.field].employees[empIndex].changed = true;
            }
            else {
              bottomRowsData[fIndex][props.params.colDef.field].employees = bottomRowsData[fIndex][props.params.colDef.field].employees.filter((e) => e.id != emp_id);
            }
            store.commit('writeDCPinnedBtmRowsData', bottomRowsData);
            setTimeout(() => {
              props.params.api.setPinnedBottomRowData(JSON.parse(JSON.stringify(bottomRowsData)));
            }, 0);
            setTimeout(() => {
              const cellElement = props.params.eGridCell;
              if (cellElement) {
                cellElement.focus();
              }
            }, 0);
          }
        }
      }
      else {
        const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1) {
          const emps = globalBaseData[fIndex][props.params.colDef.field].employees || [];
          const empIndex = emps.findIndex((gd) => gd.id == emp_id);
          if (empIndex > -1) {
            if (globalBaseData[fIndex][props.params.colDef.field].changed == null || globalBaseData[fIndex][props.params.colDef.field].changed == false) {
              globalBaseData[fIndex][props.params.colDef.field].employees[empIndex].toRemove = true;
              globalBaseData[fIndex][props.params.colDef.field].changed = true;
              globalBaseData[fIndex][props.params.colDef.field].op = 'update';
            }
            else {
              globalBaseData[fIndex][props.params.colDef.field].employees = globalBaseData[fIndex][props.params.colDef.field].employees.filter((e) => e.id != emp_id);
            }
            store.commit('writeDutyChartsData', globalBaseData);
            setTimeout(() => {
              props.params.api.resetRowHeights();
            }, 0);
            setTimeout(() => {
              const cellElement = props.params.eGridCell;
              if (cellElement) {
                cellElement.focus();
              }
            }, 0);
          }
        }
      }
    }

    function onKeyDown(event) {
      if (event.key === 'Enter') {
        // Keep the focus on the grid cell after the dropdown closes
        nextTick(() => {
          const cellElement = props.params.eGridCell;
          if (cellElement) {
            cellElement.focus();
          }
        });
      }
    }

    const getValue = () => {
      const ret = null;
      if (selected.value) {
        if (rowPinned) {
          const fIndex = bottomRowsData.findIndex((gd) => gd.id == props.params.data.id);
          if (fIndex > -1) {
            if (bottomRowsData[fIndex][props.params.colDef.field] == null) {
              bottomRowsData[fIndex][props.params.colDef.field] = { date: convertToUTC(props.params.colDef.field), employees: selected.value.map((sv) => ({ changed: true, ...sv })) };
            }
            else {
              const newValues = [];
              selected.value.forEach((sv) => {
                if (bottomRowsData[fIndex][props.params.colDef.field].employees.findIndex((d) => d.id == sv.id) < 0) {
                  sv.changed = true;
                  newValues.push(sv);
                }
              });
              if (newValues.length > 0) {
                bottomRowsData[fIndex][props.params.colDef.field].employees = bottomRowsData[fIndex][props.params.colDef.field].employees.concat(newValues);
              }
            }
            store.commit('writeDCPinnedBtmRowsData', bottomRowsData);
            setTimeout(() => {
              props.params.api.setPinnedBottomRowData(JSON.parse(JSON.stringify(bottomRowsData)));
            }, 0);
          }
        }
        else {
          const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
          if (fIndex > -1) {
            if (globalBaseData[fIndex][props.params.colDef.field] == null) {
              globalBaseData[fIndex][props.params.colDef.field] = { changed: true, op: 'create', date: convertToUTC(props.params.colDef.field), employees: selected.value.map((sv) => ({ toAdd: true, ...sv })) };
            }
            else {
              const newValues = [];
              selected.value.forEach((sv) => {
                if (globalBaseData[fIndex][props.params.colDef.field].employees.findIndex((d) => d.id == sv.id) < 0) {
                  sv.toAdd = true;
                  newValues.push(sv);
                }
              });
              if (newValues.length > 0) {
                globalBaseData[fIndex][props.params.colDef.field].employees = globalBaseData[fIndex][props.params.colDef.field].employees.concat(newValues);
                globalBaseData[fIndex][props.params.colDef.field].op = globalBaseData[fIndex][props.params.colDef.field].op == 'create' && globalBaseData[fIndex][props.params.colDef.field].changed == true ? 'create' : 'update';
                globalBaseData[fIndex][props.params.colDef.field].changed = true;
              }
            }
            store.commit('writeDutyChartsData', globalBaseData);
            setTimeout(() => {
              props.params.api.resetRowHeights();
            }, 0);
          }
        }
      }
      return ret;
    };

    const values = () => {
      let ret = [];
      if (rowPinned) {
        const fIndex = bottomRowsData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1 && bottomRowsData[fIndex][props.params.colDef.field] != null) {
          ret = bottomRowsData[fIndex][props.params.colDef.field].employees.filter((emp) => emp.toRemove == null);
        }
      }
      else {
        const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1 && globalBaseData[fIndex][props.params.colDef.field] != null) {
          ret = globalBaseData[fIndex][props.params.colDef.field].employees.filter((emp) => emp.toRemove == null);
        }
      }
      return ret;
    }

    return {
      values,
      selected,
      docpLabels,
      markSelectedOption,
      placeHolder,
      searchDocp,
      loading,
      getValue,
      onMouseDown,
      onKeyDown,
      deleteEmp,
      rowPinned
    };
  },
};
</script>

<style scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}

.select-box{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.selected_option {
  font-weight: 700;
  color: green;
}
.selected_option:hover {
  color: white;
}
</style>
