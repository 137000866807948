import { v4 as uuidv4 } from 'uuid';
import pako from 'pako';
import axios from 'axios';
axios.defaults.timeout = 600000;
const CancelToken = axios.CancelToken;
import store from '../../store/';
import { usePaths } from '../composables/useFilePath.js';

const { serverWsAddress, axiosFileUpload } = usePaths();


// object to store the websocket to the server
let _ws = null
let retryTimeout = null;

const imageWorker = new Worker(/* webpackChunkName: "image-worker" */ new URL('./imageWorker.js', import.meta.url));
imageWorker.onmessage = (evt) => {
  const data = evt.data;
  const msgid = data.msgid;
  const emitEvent = new CustomEvent(`${msgid}`, {
    bubbles: false,
    detail: data
  });

  window.dispatchEvent(emitEvent);
}

function getConnection() {
  return _ws
}

function retry() {
  console.log('Unable to connect. Retrying...')
  if (retryTimeout != null) {
    clearTimeout(retryTimeout)
  }
  retryTimeout = setTimeout(async() => {
    try {
      await setupConnection()
    }
    catch (err) {
      console.log('Error: error while retrying')
    }
  }, 1000)

}

function setupConnection() {
  return new Promise((resolve, reject) => {
    try {
      let resolved = false;
      // Return if the websocket is alive and ready
      if (_ws != null && _ws.readyState === 1) {
        resolved = true;
        resolve()
      }
      // Terminate connections which are not in correct state
      else if (_ws != null && _ws.readyState !== 1 && _ws.readyState !== 0) {
        _ws.close()
        _ws = null;
        resolve();
        retry()
      }
      else {
        _ws = new WebSocket(serverWsAddress)

        // websocket error handler
        _ws.onerror = (err) => {
          console.error('Websocket error-> ', err.stack)
          if (!resolved) {
            reject(err)
          }
          // retry()
        }

        //websocket close handler
        _ws.onclose = () => {
          console.log('Websocket closed')
          retry();
        }

        _ws.onopen = () => {
          console.log('websocket connected successfully')
          _ws.send(JSON.stringify({
            clienttype : 'sw_daybook',
            msgtype : 'hello'
          }));
          resolved = true;
          resolve()
        }

        _ws.onmessage = async(evt) => {
          let connId = null
          let emitEvent = null;
          let msgType = null;
          try {
            // uncompress incoming data
            const buff = await evt.data.arrayBuffer();
            const msg = JSON.parse(pako.inflate(buff, { to: 'string' }));
            if (msg.msgtype !== undefined) {
              if (msg.msgtype === 'broadcast' && msg.folder === 'bulk_upload') {
                const event = new CustomEvent('eventReceived', {
                  detail: msg,
                });
                window.dispatchEvent(event);
              }
            }


            connId = msg.msgid;
            msgType = msg.msgtype;
            // messages pushed from server. This is not the response to a message
            // sent earlier
            if (msgType === 'evt') {
              if (msg.clienttype === 'sw_sensors') {
                emitEvent = new CustomEvent('sensor_event', {
                  bubbles: false,
                  detail: { body: msg.body }
                });
              }
            }
            // message received as response to a sent message
            else if (connId != null && connId !== '') {
              const response = msg.body
              const responseType = response?.output?.type;

              emitEvent = new CustomEvent(connId, {
                bubbles: false,
                detail: {
                  status: 'success',
                  data: response
                }
              })
              if (responseType === 'error') {
                if (response.output.message === 'check_session failed with error: Session Expired Please Login again') {
                  console.log('Session Expired Please Login again');
                } else {
                  reject(response.output.msg)
                }
              }
            }
          }
          catch (err) {
            if (connId != null) {
              emitEvent = new CustomEvent(connId, {
                bubbles: false,
                detail: {
                  status: 'error',
                  data: err.stack
                }
              })
            }
            else {
              console.log('websocket onmessage error : ', err.stack)
            }
          }
          finally {
            if (emitEvent != null) {
              if (msgType === 'evt') {
                window.dispatchEvent(emitEvent);
              }
              else {
                _ws.dispatchEvent(emitEvent);
              }
            }
            if (connId != null && connId === '') {
              _ws.removeEventListener(connId, () => {});
            }
          }
        }
      }
    }
    catch (err) {
      reject(err)
      retry();
    }
  })
}

function waitForConnection(resolve) {
  if (_ws != null && _ws.readyState === 1) {
    resolve();
  }
  else {
    setTimeout(waitForConnection.bind(this, resolve), 1000)
  }
}

async function isWebsocketConnected() {
  return new Promise((resolve, reject) => {
    waitForConnection(resolve);
  })
}

function sendMessage(data, msgProperty) {
  return new Promise(async(resolve, reject) => {
    try {
      const session_key = store.getters['sessionIdGetter'];
      if (data?.client_props != null &&
    ['C', 'U', 'D'].indexOf(data.client_props.txn_op) > -1 &&
    data.name != null &&
    data.name.indexOf('list') < 0 &&
    data.name.indexOf('show') < 0) {
        store.commit('writeBlockingMutation', true);
      }
      await isWebsocketConnected();
      let msgtype = 'txn';
      let clienttype = 'sw_client';
      if (msgProperty != null) {
        msgtype = msgProperty.msgtype;
        clienttype = msgProperty.clienttype;
      }
      const msgId = uuidv4()
      const evtFn = (evt) => {
        const evtDetail = evt.detail
        if (evtDetail.status === 'error') {
          reject(evtDetail.data)
        }
        else {
          resolve(evtDetail.data)
        }
        if (data?.client_props != null &&
      ['C', 'U', 'D'].indexOf(data.client_props.txn_op) > -1 &&
      data.name != null &&
      data.name.indexOf('list') < 0 &&
      data.name.indexOf('show') < 0) {
          store.commit('writeBlockingMutation', false);
        }
      }
      _ws.addEventListener(msgId, evtFn)
      //Adding app config in every request
      const payload = JSON.stringify({
        msgid: msgId,
        msgtype: msgtype,
        clienttype: clienttype,
        clientid: 'TBD',
        body: {
          session_key,
          ...data,
          //app: '1.0'
          // TODO: replace it with the below comment after adding sessions_key in sendMessage
          app: {
            'version': '2.0.0',
            'user': 'wfm'
          }
        }
      })

      _ws.send(payload);
    }
    catch (err) {
      reject(err)
    }
  })
}


async function fileUploads(fileDetails) {
  try {
    let res = null
    if (fileDetails.fileObject != null) {
      const fileName = fileDetails.name;
      const formData = new FormData();
      const fileContent = fileDetails.fileObject;
      const blob = new Blob([fileContent], { type: fileDetails.fileObject.type });
      const payload = {
        baseFolderPath : fileDetails.baseFolderPath,
        folder : fileDetails.folder,
        foldertype: fileDetails.foldertype,
        swhandle : fileDetails.swhandle,
        name : fileDetails.name,
        params : fileDetails.params,
        target_folder : fileDetails.target_folder,
        session_key : fileDetails.session_key,
      }
      formData.append('msg', JSON.stringify({
        msgtype: 'file_upload',
        id: uuidv4(),
        body: payload
      }))
      formData.append('files', blob, fileName);
      const headers = { 'Content-Type': 'multipart/form-data' };
      // const formDataLength = formData.getLengthSync();
      let formDataLength = 0;
      for (const entry of formData.entries()) {
        // Calculate length of each entry
        formDataLength += entry[0].length + entry[1].size;
      }
      const bodyLengthInMB = Math.ceil(formDataLength / (1024 * 1024))
      const source = CancelToken.source();
      const cancelTimeout = setTimeout(() => {
        source.cancel();
      }, 6000);
      const resp = await axios.post(axiosFileUpload, formData, {cancelToken:source.token, maxBodyLength: bodyLengthInMB * 1024 * 1024, headers: headers });
      clearTimeout(cancelTimeout);
      if (resp.status === 200) {
        res = resp.data.body;
      }
      else {
        throw new Error('Invalid response from server. ' + resp.status)
      }
    }
    else {
      throw new Error('File Path is not available in request')
    }
    return res;
  }
  catch (err) {
    throw err;
  }
}
async function uploadFile(fileurl, swhandle, target_folder, session_key) {
  try {
    return await new Promise((resolve, reject) => {
      try {
        const msgid = uuidv4();
        window.addEventListener(msgid, (data) => {
          const detail = data.detail;
          if (detail.type === 'success') {
            resolve(detail.fileId);
          }
          else {
            reject(detail.err)
          }
        })

        imageWorker.postMessage({
          serverAddress: axiosFileUpload, fileurl, swhandle, target_folder, session_key, msgid, uuid: uuidv4()
        });
      }
      catch (err) {
        reject(err);
      }
    })
  }
  catch (err) {
    throw err;
  }
}

async function multipleFileUploads(url, fileUrlsArr, swhandle, target_folder, session_key) {
  try {
    let res = null;
    const mapUrlFileId = {};
    const finalPatch  = [{'_path': 'BASEDATA'}];
    const pathForPatch = {_path : 'business.folders.files'};
    const fileIdsArrForPatch = [];
    const dataArrForPatch = [];
    const indexForPatch = {'dataStart': 1, 'dataEnd': fileUrlsArr.length + 1, 'pathIndexes': [fileUrlsArr.length + 1]};
    for (let i = 0; i < fileUrlsArr.length; i++) {
      const fileId = uuidv4();
      mapUrlFileId[fileUrlsArr[i]] = fileId;
      const fileUrl = fileUrlsArr[i];
      const fileExt = fileUrl.split('.');
      const fileName = fileId + '.' + fileExt[fileExt.length - 1];
      fileIdsArrForPatch.push({files_id : fileId});
      dataArrForPatch.push({id : fileId, original_filename : fileName});
      indexForPatch[fileId] = `${i + fileUrlsArr.length}__C`;
    }
    finalPatch.push(...fileIdsArrForPatch);
    finalPatch.push(pathForPatch);
    finalPatch.push(...dataArrForPatch);
    finalPatch.push(indexForPatch);

    const payload = {baseFolderPath : 'business.folders.files', folder : 'files', foldertype: 'folders', swhandle : swhandle, name : 'txn_files_create', params : finalPatch, target_folder : target_folder, session_key : session_key, app : {'version': '2.0.0', 'user': 'wfm'}};

    const formData = new FormData();
    formData.append('msg', JSON.stringify({
      msgtype: 'txn',
      id: uuidv4(),
      body: payload
    }))
    for (let i = 0; i < fileUrlsArr.length; i++) {
      const fileUrl = fileUrlsArr[i];
      const fileExt = fileUrl.split('.');
      const fileName = mapUrlFileId[fileUrl] + '.' + fileExt[fileExt.length - 1];
      const fileBlob = await fetch(fileUrl);
      const blobs = await fileBlob.blob();
      formData.append('files', new File([blobs], fileName, { type: blobs.type }));
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    let formDataLength = 0;
    for (const entry of formData.entries()) {
      // Calculate length of each entry
      formDataLength += entry[0].length + entry[1].size;
    }
    const bodyLengthInMB = Math.ceil(formDataLength / (1024 * 1024))
    const source = CancelToken.source();
    const cancelTimeout = setTimeout(() => {
      source.cancel();
    }, 600000);
    const resp = await axios.post(url, formData, {cancelToken:source.token, maxBodyLength: bodyLengthInMB * 1024 * 1024, headers: headers });
    clearTimeout(cancelTimeout);
    if (resp.status === 200) {
      res = resp.data.body;
    }
    else {
      throw new Error('Invalid response from server. ' + resp.status)
    }

    if (res.body.output.type === 'success') {

    } else {
      throw new Error('File not upload successfully. ' + res.body.output.data.error);
    }
    return mapUrlFileId;
  }

  catch (err) {
    throw err;
  }
}

export  { setupConnection, sendMessage, getConnection, fileUploads, multipleFileUploads, uploadFile}
