<template>
  <div v-if="(fieldOptionsToUse?.type === 'text' || fieldOptionsToUse?.type === ' text') && controlType!='date'">
    <WfmText
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div
    v-else-if="fieldOptionsToUse?.type === 'timestamp' || controlType=='date'"
  >
    <WfmTimestamp
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div
    v-else-if="fieldOptionsToUse?.type === 'boolean'"
    class="row"
  >
    <WfmBoolean
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'textarea'">
    <WfmTextArea
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'docpicker' || fieldOptionsToUse?.type === 'dropdown'">
    <WfmDocpFile
      v-if="fieldOptionsToUse?.file_upload===true"
      :params="params"
      @input-change="handleInputChange"
    />
    <WfmDocPicker
      v-else
      :params="params"
      :disabled="disabled"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'shift_range'">
    <WfmShiftRange
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'tsrange' || fieldOptionsToUse?.type === 'interval'">
    <WfmTsRange
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'uuid'">
    <WfmUuid
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'loctext'">
    <WfmLocText
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'nsp'">
    <WfmNsp
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'dow_type'">
    <WfmDowType
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div v-else-if="fieldOptionsToUse?.type === 'float'">
    <WfmFloat
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <div
    v-else-if="fieldOptionsToUse?.type === 'number' ||
      fieldOptions?.type === 'int' ||
      fieldOptions?.type === 'numeric'
    "
  >
    <WfmNumber
      :params="params"
      @input-change="handleInputChange"
    />
  </div>

  <div v-else-if="fieldOptionsToUse?.type === 'source_type'">
    <WfmSourceType
      :params="params"
      @input-change="handleInputChange"
    />
  </div>
  <WfmDetail
    v-else-if="fieldOptionsToUse?.type === 'detail'"
    :params="params"
    @input-change="handleInputChange"
  >
    <template #default="slotProps">
      <div
        class="table-container"
        :style="{height: changeHeight(slotProps.detailGridApi)}"
      >
        <AgGrid
          data-test-id="testing"
          grid-id="testingId"
          grid-class-name="ag-theme-material"
          :column-defs="slotProps.columnDef"
          :row-data="slotProps.rowData"
          :unique-key="slotProps.uniqueKey"
          :detail-name="slotProps.detailName"
          :row-model-type="slotProps.rowModelType"
          :dom-layout="slotProps.domLayout"
          :pagination-page-size="slotProps.paginationPageSize"
          :pagination="slotProps.pagination"
          :suppress-pagination-panel="slotProps.suppressPaginationPanel"
          :header-height="slotProps.headerHeight"
        />
      </div>
    </template>
  </WfmDetail>
</template>

<script>
import AgGrid from '../../components/AgGrid.vue';
import WfmNumber from './wfm-number.vue';
import WfmText from './wfm-text.vue';
import WfmBoolean from './wfm-boolen.vue';
import WfmTextArea from './wfm-textarea.vue';
import WfmDocPicker from './wfm-docpicker.vue';
import WfmTimestamp from './wfm-timestamp.vue';
import WfmShiftRange from './wfm-shift-range.vue';
import WfmUuid from './wfm-uuid.vue';
import WfmLocText from './wfm-loctext.vue';
import WfmDetail from './wfm-detail.vue';
import WfmFloat from './wfm-float.vue';
import WfmNsp from './wfm-nsp.vue';
import WfmSourceType from './wfm-source-type.vue';
import WfmTsRange from './wfm-ts-range.vue';
import WfmDowType from './wfm-dow-type.vue';
import { useStore } from 'vuex';
import { ref, computed, inject} from 'vue';
import {keys} from '../../provideKeys.js'
import WfmDocpFile from './wfm-docp-file.vue';
import getUtility from '../../composables/getUtility.js'
import controlUtility from '../../composables/controlUtility';
import getFolders from '../../composables/getFolders';
import getFormFieldOptions from '../../composables/getFormFieldOptions';
export default {
  name: 'WfmControl',
  components: {
    WfmText,
    WfmBoolean,
    WfmTextArea,
    WfmDocPicker,
    WfmTimestamp,
    WfmShiftRange,
    WfmUuid,
    WfmLocText,
    WfmDetail,
    WfmFloat,
    WfmNsp,
    AgGrid,
    WfmNumber,
    WfmSourceType,
    WfmTsRange,
    WfmDowType,
    WfmDocpFile
  },
  props: {
    fieldOptions: {
      type: Object,
      String,
      default: null,
    },
    data: {
      type: Object,
      String,
      default: null,
    },
    detailName: {
      type: Object,
      String,
      default: null,
    },
    makeParams: {
      type: Boolean,
      default: false,
    },
    currentFolder:{
      type:Object,
      default:null
    },
    path:{
      type:String,
      default:null
    },
    filledData:{
      type:Object,
      default:null
    },
    controlType:{
      type:String,
      default:null
    },
    fieldInfo:{
      type:Object,
      default:null
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  setup(props, context) {
    //variables
    const store = useStore();
    const {defaultValuesAssign} = controlUtility()
    const {getFolderByName} = getFolders()
    const bSettings = store.getters['bSettings'];
    const propertyName = ref(props.fieldOptions?.name || props.fieldInfo?.name);
    const detailChangedData = ref([]);
    const currentTask = inject(keys.currentTask)
    const folderFields = inject(keys.folderFields, [])
    const mandatoryStatus = inject(keys.mandatoryFilter, false)
    const isEditNotAllowed = inject(keys.editNotAllowed, false)
    const missingFields = inject(keys.missingFields, [])
    const mandatoryFields = inject(keys.mandatoryFields, [])
    const invalidFields = inject(keys.invalidFields, [])
    const clearFormState = inject(keys.clearFormState, false)
    //injecting the formData
    const formData = inject(keys.formData)

    const fieldOptionsToUse = computed(() => {
      const options = props.fieldOptions ?? getFieldOptions();
      const userData = props.filledData
      if (currentTask == 'create') {
        return defaultValuesAssign(userData, options);
      }
      return options;
    });
    // const dataToSend = computed(() => {
    //   if (currentTask == 'create') {
    //     return props.data
    //   }
    //   else {
    //     return props.data
    //   }
    // })
    //making params to pass to controls
    const params = computed(() => {
      return {
        fieldOptions:fieldOptionsToUse.value,
        data:clearFormState.value ? emptyFields.value : props.data,
        currentFolder:props.currentFolder,
        mandatoryStatus:mandatoryStatus,
        isEditNotAllowed:isEditNotAllowed,
        missingFields:missingFields,
        mandatoryFields:mandatoryFields,
        invalidFields:invalidFields
      }
    })
    //folder fields with null value to send when data cleared
    const emptyFields = computed(() => {
      const fieldsCopy = folderFields.value
      const {getNullObject} = getUtility()
      return getNullObject(fieldsCopy)
    })

    function getFieldOptions() {
      if (props.path || props?.fieldInfo?.path) {
        const baseFolderName = props.path.split('.')[0]
        const baseFolder = getFolderByName(baseFolderName, bSettings)
        const params = {
          path:props?.path,
          currentFolder:baseFolder,
          bSettings:bSettings?.output.data.records[0],
          ...props.fieldInfo
        }
        const { fieldsData } = getFormFieldOptions();

        const fieldInfo = fieldsData(params);
        return fieldInfo ? fieldInfo : null;
      }


    }
    function changeHeight(props) {
      //if the slotporps of detailGrid api is not destroyed change the height accordingly
      if (props && !fieldOptionsToUse.value.exceptionDetail) {
        if (!props.gridApi.destroyCalled) {
          return  props?.gridApi?.getDisplayedRowCount() == 0 ? '150px' : '300px'
        }
      }
      else {
        return '200px'
      }
    }
    const childValue = ref('');

    function handleInputChange(newValue, basePath) {
      try {
        //emitted change in the control values handled here
        childValue.value = newValue;
        childValue.value.fieldType = fieldOptionsToUse.value.type
        if (fieldOptionsToUse.value.type != 'detail' && !fieldOptionsToUse.value.is_detail) {
          let firstPath = null
          if (newValue.path != null) {
            firstPath = newValue.path.split('.')[0]
          }
          //if change is coming from other controls(outside detail tables)
          formData.value[propertyName.value] = childValue.value;
          if (firstPath && firstPath === props.currentFolder.name) {
            if (folderFields?.value) {
              folderFields.value[propertyName.value] = newValue?.value;
            }
          }
          else {
            let pathArray = null
            if (newValue.path != null) {
              pathArray = newValue.path.split('.')

              if (folderFields && folderFields.value.hasOwnProperty(pathArray[0])) {
                if (Array.isArray(folderFields.value[pathArray[0]])) {
                  folderFields.value[pathArray[0]][0][pathArray[1]] = newValue.value
                }
                else {
                  folderFields.value[pathArray[0]] = []
                  folderFields.value[pathArray[0]].push({[propertyName.value]:newValue.value})
                }
              }
            }
            // else {
            //   const nameOfFolder = props.currentFolder.name

            //   if (folderFields.value) {
            //     folderFields.value[nameOfFolder] = {[propertyName.value]:newValue?.value};
            //   }
            //   else {
            //     folderFields.value = {}
            //     folderFields.value[nameOfFolder] = {}
            //     folderFields.value[nameOfFolder] = {[propertyName.value]:newValue?.value};

            //   }
            // }
          }

        } else {
          //if change coming from within the detail table
          if (!fieldOptionsToUse.value.is_detail) {
            if (!detailChangedData.value.some((item) => item.id === (newValue ? newValue.id : undefined))) {
              detailChangedData.value.push(newValue);
              formData.value[propertyName.value] = detailChangedData.value;
              if (folderFields.value) {
                folderFields.value[propertyName.value] = detailChangedData.value;

              }
            }
          }
          else {
            formData.value[propertyName.value] = newValue.value;

          }


        }
      } catch (error) {
        throw error
      }

    }
    return {
      formData,
      handleInputChange,
      changeHeight,
      params,
      fieldOptionsToUse
    };
  },
};
</script>

<style scoped>
.detail-table {
  color: red;
  background-color: antiquewhite;
}

.table-container {
  width: 100%;
  z-index: 0 !important;
  border:0px !important;
}
  ::ng-deep .ag-root-wrapper {
    overflow: visible;
  }

</style>
