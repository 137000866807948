import { createStore } from 'vuex';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import userModule from './modules/userModule';
import folderModule from './modules/folderModule';
import agGridModule from './modules/agGridModule';
import commonModule from './modules/commonModule';

const store = createStore({
  state: {
    bSettings: null,
    fieldName: null,
    props: null,
    session_key: null,
    serverPort:null,
    listTxnArray: [],
    writes_blocked: false,
    gen_payslips: false,
    selectedEmps: [],
    dutyCharts: [],
    dutyChartPinnedBottomRows: []
  },
  getters,
  mutations,
  actions,
  modules: {
    userModule,
    folderModule,
    agGridModule,
    commonModule
  },
})

export default store;
