<template>
  <div class="d-flex">
    <div
      v-if="!isWeekPicker"
      @click="prevMonth"
    >
      <v-icon
        name="bi-caret-left-fill"
        scale="1.5"
        class="mt-1"
      />
    </div>
    <VueDatePicker
      ref="datePicker"
      v-model="dateValue"
      :class="customDpClass"
      :ui="{ input: 'bg-light text-dark' }"
      :format="format"
      :teleport="true"
      hide-input-icon
      :min-date="folderName!='leaves'?new Date('2024-11-01'):null"
      :month-picker="!isWeekpicker"
      :week-picker="isWeekpicker"
      :dark="darkMode"
      @update:model-value="handleChange"
    >
      <template #clear-icon="{ clear }">
        <span
          class="input-slot-image"
          @click="clear"
        />
      </template>
    </VueDatePicker>
    <div
      v-if="!isWeekPicker"
      class="my-auto"
      @click="nextMonth"
    >
      <v-icon
        name="bi-caret-right-fill"
        scale="1.5"
        class="mt-1"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  name: 'WfmDateSwitch',
  props: {
    isWeekPicker: {
      type: Boolean,
      default: () => false
    },
    customDpClass:{
      type:Object,
      default:null
    },
    defaultDate:{
      type:Object,
      default:null
    },
    folderName:{
      type:String,
      default:''
    }
  },
  emits: ['update-month-year'],
  setup(props, context) {
    //variables
    const isWeekpicker = props.isWeekPicker;
    const dateValue = ref(props.defaultDate);
    const datePicker = ref(null);
    //computed
    const darkMode = computed(() => {
      const themechange = localStorage.getItem('themeDark');
      if (themechange === 'true') {
        return true;
      } else {
        return false;
      }
    });
    //methods

    const emitUpdateMonthYear = (newDate) => {
      if (isWeekpicker) {
        if (Array.isArray(newDate)) {
          const monthYear = {
            weekRange: {
              startOfWeek: newDate[0].getDate(),
              startMonth: newDate[0].getMonth(),
              startYear: newDate[0].getFullYear(),
              endOfWeek: newDate[1].getDate(),
              endMonth:newDate[1].getMonth(),
              endYear:newDate[1].getFullYear()
            },
            day: null,
            year:  newDate[0].getFullYear(),
            month: newDate[0].getMonth(),
          };
          context.emit('update-month-year', monthYear);
        } else {
          const weekRange = getWeekRange(newDate);
          const monthYear = {
            weekRange: {
              startOfWeek: weekRange.startDay,
              startMonth: weekRange.startMonth,
              startYear: weekRange.startYear,
              endOfWeek:weekRange.endDay,
              endMonth:weekRange.endMonth,
              endYear:weekRange.endYear
            },
            day: null,
            year: weekRange.endYear,
            month: weekRange.endMonth,
          };
          context.emit('update-month-year', monthYear);
        }
      } else {
        const year = newDate.year;
        const monthName = newDate.month + 1;
        const monthYear = { day: 1, year: year, month: monthName };
        context.emit('update-month-year', monthYear);
      }
    };

    const handleChange = (newValue) => {
      dateValue.value = newValue;
      emitUpdateMonthYear(dateValue.value);
    };

    const prevMonth = () => {
      if (dateValue.value.month === 10 && dateValue.value.year === 2024 && props.folderName != 'leaves') {
        return
      }
      if (dateValue.value.month > 0) {
        dateValue.value.month--;
      } else {
        dateValue.value.month = 11;
        dateValue.value.year--;
      }
      emitUpdateMonthYear(dateValue.value);
    };

    const nextMonth = () => {
      if (dateValue.value.month < 11) {
        dateValue.value.month++;
      } else {
        dateValue.value.month = 0;
        dateValue.value.year++;
      }
      emitUpdateMonthYear(dateValue.value);
    };


    function getWeekRange(currentDateTime) {
      const current = new Date(currentDateTime);
      const dayOfWeek = current.getDay();
      const startOfWeek = new Date(current);
      startOfWeek.setDate(current.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      return {
        startDay: startOfWeek.getDate(),
        startMonth: startOfWeek.getMonth(),
        startYear: startOfWeek.getFullYear(),
        endDay: endOfWeek.getDate(),
        endMonth: endOfWeek.getMonth(),
        endYear: endOfWeek.getFullYear()
      }
    }

    function format(date) {
      if (date) {
        if (isWeekpicker) {
          const weekRange = getWeekRange(date);
          const startMonthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(`${weekRange.startYear}-${weekRange.startMonth + 1}-01`));
          const endMonthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(`${weekRange.endYear}-${weekRange.endMonth + 1}-01`));
          return `${weekRange.startDay} ${startMonthName} ${weekRange.startYear} : ${weekRange.endDay} ${endMonthName} ${weekRange.endYear}`;
        }
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Adjust for zero-based month
        const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(`${year}-${month}-01`));
        return `${monthName} ${year}`;
      }
      return '';
    }


    onMounted(() => {
      if (props.defaultDate) {
        dateValue.value = props.defaultDate;
      } else {
        const today = new Date();
        dateValue.value = { month: today.getMonth(), year: today.getFullYear() };
      }
      emitUpdateMonthYear(isWeekpicker ? new Date() : dateValue.value);
    });

    return {
      isWeekpicker,
      prevMonth,
      nextMonth,
      dateValue,
      format,
      datePicker,
      handleChange,
      darkMode
    };
  },
};
</script>

<style scoped>
.dp__input_wrap {
  width: 200px !important;
}


</style>
