<template>
  <div class="mx-4">
    <WfmTabsHorizontal
      :key="tabKey"
      :tabs-list="tabsList"
      :selected-index="selectedIndex"
      @add-tab="addTab"
      @del-tab="delTab"
      @change-tab="changeTab"
      @refresh-data="refreshData"
    />
  </div>
</template>
<script>
import WfmTabsHorizontal from '../../common/wfm-tabs-horizontal.vue';
import { ref, computed, onMounted} from 'vue';
import {useRoute} from 'vue-router'
import getFolders from '../../composables/getFolders';
import { useStore } from 'vuex';
export default {
  name: 'TabsPage',
  components: {
    WfmTabsHorizontal,
  },
  setup(props) {
    const dataReady = ref(false)
    const tabKey = ref(0);
    const route = useRoute()
    const selectedIndex = ref(0)
    const pageName = route.params.name
    const store = useStore()
    const pageLabel = pageName.replace('_', ' ');
    const bSettings = store.getters['bSettings'];

    const {getCurrentFolder, folderListToUse} = getFolders();
    const folderList = folderListToUse(bSettings);
    //currentFolder being derived from route params name
    const currentFolder = computed(() => {
      const folderName = route.params.name.split('/')[0]
      return getCurrentFolder(folderName, folderList)
    })
    const hasAdminRights = computed(() => {
      return bSettings?.env?.isAO || bSettings?.env?.isRO || bSettings?.env.isHR || bSettings?.env?.code == 'admin'
    })
    const tabsList = ref()

    //methods
    function changeTab(index) {
      selectedIndex.value = index
    }
    function addTab(tabSpec) {
      const idx = tabsList.value.findIndex((obj) => obj.name === tabSpec.name)
      if (idx < 0) {
        tabsList.value.push(tabSpec)
        selectedIndex.value = tabsList.value.length - 1
      }
      else {
        selectedIndex.value = idx
      }
    }

    function delTab(index) {
      tabsList.value.splice(index, 1)
    }
    function refreshData(rowData) {
      const code = rowData.code ||  rowData.employees_id?.[0]?.code || rowData?.shift_code || rowData?.name?.eng;
      tabsList.value[selectedIndex.value].name = 'update ' + code,
      tabsList.value[selectedIndex.value].params['row-data'] = rowData
    }

    onMounted(() => {
      if (route.params.name == 'payslips/generate') {
        tabsList.value = [
          {
            label:pageLabel,
            component:'PayslipGenerate',
            name:'Generate Payslips',
            params:{
              readOnly:false, folder:currentFolder.value, currentTask:'generate'
            }
          },
        ]
      }
      else if (route.params.name.split('/')[0] === 'view_report') {
        const reportName = route.params.name.split('/')[1]
        tabsList.value = [
          {
            label:pageLabel,
            component:'ReportsView',
            name:reportName,
            params:{
              readOnly:false
            }
          },
        ]
      }
      else if (route.params.name.split('/')[0] === 'leaves' && !hasAdminRights.value) {
        // const leavesName = route.params.name.split('/')[1]
        // console.log('leavesName', leavesName)
        const {getFolderByName} = getFolders()
        const leavesFolder = getFolderByName('leaves', bSettings)
        tabsList.value = [
          {
            label:pageLabel,
            component:'LeavesCalendar',
            name:'Leaves',
            params:{
              readOnly:false,
              folder:leavesFolder
            }
          },
        ]
      }
      else {
        console.log('route params', route?.params?.name, bSettings?.env?.code)
        // let searchPath = null
        // let searchTerm = null
        // // if (hasAdminRights.value && bSettings.env.code !== 'admin' && currentFolder.value.name == 'leaves' && !bSettings.env.isAO) {
        // //   searchPath = 'leaves.employees_id.reporting_manager.code'
        // //   searchTerm = bSettings.env.code
        // // }
        tabsList.value = [{
          label: pageLabel + ' list',
          component: 'FolderList',
          name: currentFolder.value?.label?.eng,
          params: { readOnly: false, folder:currentFolder.value}
        }]
      }
    })
    return {
      tabsList,
      addTab,
      delTab,
      tabKey,
      changeTab,
      dataReady,
      selectedIndex,
      refreshData
    };
  }
};
</script>


<style scoped>
</style>
