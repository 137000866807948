<template>
  <div
    :key="rowIndex"
    class="progress square-edge"
    :data-test-id="`progress-bar/${rowIndex}`"
  >
    <div
      :data-test-id="`progressBar/${rowIndex}`"
      class="progress-bar bg-success text-dark overflow-visible"
      role="progressbar"
      :aria-valuenow="currentProgress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: `${currentProgress}%` }"
    >
      <b
        v-if="!processed"
        class="justify-content-center d-flex position-absolute w-100"
        name="status"
        :class="{'text-danger':error}"
      > {{ displayMessage }}% processed</b>
      <b
        v-else
        class="justify-content-center d-flex position-absolute w-100"
        name="message"
        :class="{error:'text-danger'}"
      >{{ displayMessage }}</b>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed} from 'vue';
export default {
  name: 'WfmProgressLoader',
  props:{
    folderName:{
      type:String,
      default:null
    }
  },
  emits:['generated', 'all-process-done'],
  setup(props, context) {
    //variables
    const stages = ref();
    const progress = ref();
    const msg = ref('0');

    const currentProcessId = ref(props?.params?.data?.id);

    const rowIndex = props.params?.node?.id || null

    const error = ref(false)
    const currentProgress = ref(2);
    const processed = computed(() => {
      if (props?.params?.data?.processed == true) {
        return true
      }
      else {
        return false
      }
    })

    const displayMessage = computed(() => {
      if (processed.value) {
        return 'Completed'
      }
      return msg.value
    })

    const handleBroadcastMessage = (event) => {
      if (currentProcessId.value === event?.detail?.id) {
        progress.value = event.detail.count;
        const [currentStage, totalStages] = event.detail?.stage?.split('/');
        const [processed, total] = event.detail.count.split('/');
        const stageProgress = (parseInt(currentStage) - 1) / parseInt(totalStages);
        const recordProgress = (parseInt(processed) / parseInt(total)) / parseInt(totalStages);
        currentProgress.value = (stageProgress * 100) + (recordProgress * 100);
        const statusMsg = event.detail.msg
        const rowNode = props.params.node
        rowNode.setDataValue('status', statusMsg)

        msg.value = Math.round(currentProgress.value * 100) / 100;
        if (currentStage == totalStages && total == processed) {
          rowNode.setDataValue('processed_status', true)
        }
      }
    };

    const handlePayslipsBroadcast = (event) => {
      const folder = event.detail?.ctrl?.folder
      if (folder == 'payslips') {
        const msgType = event.detail?.ctrl?.output?.type
        progress.value = event.detail.count;
        const [currentStage, totalStages] = event.detail.stage.split('/');
        const [processed, total] = event.detail.count.split('/');
        const stageProgress = (parseInt(currentStage) - 1) / parseInt(totalStages);
        const recordProgress = (parseInt(processed) / parseInt(total)) / parseInt(totalStages);
        currentProgress.value = (stageProgress * 100) + (recordProgress * 100);
        msg.value = Math.round(currentProgress.value * 100) / 100;

        if (msgType == 'error') {
          error.value = true
        }
        context.emit('generated', event.detail)
        if (currentStage == totalStages && total == processed) {
          context.emit('all-process-done', true)
        }
      }
    }
    onMounted(() => {

      if (props.folderName == 'payslips') {
        window.addEventListener('eventReceived', handlePayslipsBroadcast);
      }
      else {
        window.addEventListener('eventReceived', handleBroadcastMessage);
      }
      if (processed.value === true) {
        msg.value = 'Completed';
        currentProgress.value = 100
      } else {
        msg.value = '0';
      }
    });

    return {
      stages,
      msg,
      progress,
      currentProgress,
      processed,
      rowIndex,
      error,
      displayMessage
    };
  },
};
</script>

<style>
.progress {
  --bs-progress-bg: #cdd1ce !important;
}
.text-danger{
  color:red !important;
}
.progressBar  {
  justify-content: center;
  align-items: center;
}

.square-edge {
  border-radius: 0;
  margin: 10px;
}
</style>
