<template>
  <div
    v-if="attStatus.show"
    class="register-renderer-container"
  >
    <div class="att-type-container">
      <div class="register-status">
        <div
          v-if="attStatus.isDeficit !== null && attStatus.isDeficit"
          class="status-icon"
        >
          *
        </div>
        <div
          v-if="attStatus.systemEdit !== null && attStatus.systemEdit"
          class="flag orange"
          alt="S"
        >
          <!---->
        </div>
        <div
          v-if="attStatus.manualEdit !== null && attStatus.manualEdit"
          class="flag red"
          alt="M"
        >
          <!---->
        </div>
      </div>
      <div class="register-status">
        <span class="attendance">{{ attStatus.attendance }}</span>
      </div>
      <div class="register-type">
        <div
          v-if="attStatus.approvedAO !== null && attStatus.approvedAO"
          class="status-icon"
        >
          <v-icon
            name="bi-check-all"
            scale="1.2"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-else-if="attStatus.approvedRO !== null && attStatus.approvedRO"
          class="status-icon"
        >
          <v-icon
            name="bi-check"
            scale="1.2"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-if="attStatus.rejected !== null && attStatus.rejected"
          class="status-icon"
          alt="E"
        >
          <v-icon
            name="bi-x"
            scale="1.0"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-if="attStatus.isSettled !== null && attStatus.isSettled"
          class="status-icon"
          alt="E"
        >
          <v-icon
            name="bi-check-circle-fill"
            scale="0.8"
            fill="rgb(69 98 69)"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="register-renderer-container"
  >
    <!-- comment -->
  </div>
</template>

<script>
import {
  computed,
} from 'vue';
export default {
  name: 'AttendanceTypeCellRenderer',
  setup(props) {

    function attData(props, show) {
      let systemEdit = false;
      let manualEdit = false;
      if (props.params.data.attendanceData !== undefined && props.params.value.attendanceData !== null && props.params.data.attendanceData.exception !== null && props.params.data.attendanceData.exception !== undefined) {
        for (let i = 0; i < props.params.data.attendanceData.exception.length; i++) {
          if (props.params.data.attendanceData.exception?.[i]?.type === 'system' && !systemEdit) {
            systemEdit = true;
          } else if (props.params.data.attendanceData.exception?.[i]?.type === 'manual' && !manualEdit) {
            manualEdit = true;
          }
        }
      }
      let retVal = {};
      const targetElement = props.params.eGridCell;
      let attendance = 'att_type' in props.params.data ? props.params.data['att_type']?.[0]?.['name']['eng'] : null;
      let attendanceCode = 'att_type' in props.params.data ? props.params.data['att_type']?.[0]?.['code'] : null;
      if (attendance === undefined) {
        attendance = props.params.data['att_type']?.[0]?.['name'];
      }
      if(props.params.data.att_type[0]?.code == 'L' && props.params.data.leave_type != null && props.params.data.leave_type.length > 0){
        attendance = props.params.data.leave_type[0].code;
      }
      if (attendanceCode !== 'HD' && attendanceCode === 'L' && props.params.data.attendanceData !== undefined && props.params.value.attendanceData !== null && props.params.data.attendanceData.leave_type !== undefined && props.params.data.attendanceData.leave_type !== null && props.params.data.attendanceData.leave_type.length > 0) {
        attendance = props.params.data.attendanceData.leave_type?.[0]?.code;
        attendanceCode = props.params.data.attendanceData.leave_type?.[0]?.code;
      }
      let approvedAO = null;
      let approvedRO = null;
      let rejected = null;
      let isSettled = null;
      if (props.params.data.attendanceData !== undefined && props.params.value.attendanceData !== null && 'attendance_status' in props.params.data.attendanceData) {
        if (props.params.data.attendanceData['attendance_status']?.[0].code === 'final_approved') {
          approvedAO = true;
        } else if (props.params.data.attendanceData['attendance_status']?.[0].code === 'intermediate_approved') {
          approvedRO = true;
        } else if (props.params.data.attendanceData['attendance_status']?.[0].code === 'rejected') {
          rejected = true;
        } else if (props.params.data.attendanceData['attendance_status']?.[0].code === 'settled') {
          isSettled = true;
        }
      }
      // if (attendanceCode === 'PR') {
      //   if (approvedAO !== null && approvedAO) {
      //     attendance = 'Present';
      //     attendanceCode = 'P'
      //   }
      // }
      retVal = {
        'show': show,
        'attendance': attendance,
        'approvedAO': approvedAO,
        'approvedRO': approvedRO,
        'isSettled': isSettled,
        'rejected': rejected,
        'systemEdit': systemEdit,
        'manualEdit': manualEdit,
      };
      if (attendanceCode === 'P') {
        targetElement.style.backgroundColor = 'rgb(182 247 182)';
      } else if (attendanceCode === 'A') {
        targetElement.style.backgroundColor = 'rgb(245 210 209)';
      } else if (attendanceCode === 'H') {
        targetElement.style.backgroundColor = 'rgb(199 237 249)'
      } else if (attendanceCode === 'SP') {
        targetElement.style.backgroundColor = ' #FFFFE0'
      } else if (attendanceCode === 'PR') {
        targetElement.style.backgroundColor = '#D3D3D3'
      } else if (attendanceCode === 'HD') {
        targetElement.style.backgroundImage = 'repeating-linear-gradient(to bottom, transparent, transparent 5px, rgb(255, 234, 191) 5px, #ffffff 10px), repeating-linear-gradient(to right, rgb(255, 234, 191), rgb(255 255 255) 5px, rgb(251 217 146) 5px, rgb(255, 234, 191) 10px) ';
      } else {
        targetElement.style.backgroundColor = 'rgb(255 234 191)'
      }
      return retVal;
    }

    const attStatus = computed(() => {
      let retVal = {};
      let show = true;
      if (props.params['value'] !== undefined && props.params['value'] !== null) {
        retVal = attData(props, show);
      }
      else {
        const targetElement = props.params.eGridCell;
        targetElement.style.backgroundColor = '';
        targetElement.style.border = '';
        targetElement.style.boxShadow = '';
        targetElement.classList.remove('ag-row-selected');
        show = true;
        retVal = { 'show': show, attendance: 'Missed Punch' }
      }
      return retVal
    })

    return {
      attStatus,
    };
  }
}
</script>

  <style>
  .ag-cell {
    text-align: center;
    width: 100%;
    padding: 0px;
    height: 100%;
    border: none;
  }

  .ag-cell:focus {
    border: 1px solid rgb(0, 0, 0) !important;
  }

  .register-renderer-container {
    width: 100% !important;
    padding: 0px !important;
    height: 100% !important;
  }

  .att-type-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 0;
  }

  .register-status {
    z-index: 2;
    display: flex;
    height: 90%;
    font-size: 14px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .status-icon {
    margin: 0;
    padding: 0;
  }


  .register-type {
    z-index: 2;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-overflow: ellipsis;
  }

  .attendance {
    padding: 2px;
  }

  .flag {
      height: 7px;
      width: 7px;
      background-color: transparent;
      border-radius: 50%;
      border: 0.5px solid lightgray;
      display: inline-block;
      opacity: 1 !important;
  }

  .orange {
      background-color: orange;
  }

  .red {
      background-color: red;
  }
  </style>
