<template>
  <CustomHeaderCalendar
    v-if="calendarApi"
    :calendar-ref="calendarApi"
    :folder="folder"
    :data="data"
    :readonly-mode="readOnlyMode"
    @get-filters="getFilters"
    @bulk-txn="makeParamsToSend"
    @get-leaves-quota="changeLeaveQuota"
  />
  <div class="body-container">
    <FullCalendar
      ref="leaveCalendarRef"
      :options="calendarOptions"
    >
      <template #eventContent="arg">
        <EventCellRenderer
          :arg="arg"
          :user-data="userParams"
          :multiselect="multiselect"
          :filter-params="filterParams"
          :pending-txn="savingForm"
          @delete-event="deleteLeave"
          @multi-select="handleInputSelect"
        />
      </template>
    </FullCalendar>
    <WfmModal
      v-if="showForm"
      @close="closeModal"
    >
      <template #header>
        <div class="mx-auto my-auto">
          <h6>
            {{ currentTaskName == 'create' ? 'Create Leave' : 'Update Leave' }}
          </h6>
        </div>
      </template>
      <template #body>
        <div
          :key="formKey"
          class="form-box"
        >
          <div class="d-flex">
            <div
              v-if="missingFields.length !== 0"
              class="fs-6 mt-1"
            >
              <span class="me-2">Mandatory*</span>
            </div>
            <div class="row">
              <div
                v-for="(item, index) in missingFields"
                :key="index"
                class="mt-2 col"
              >
                <span
                  class="badge bg-danger mt-1"
                  :style="{
                    'cursor': 'pointer',
                    'font-size': '12px'
                  }"
                  @click="scrollToTheField(item)"
                >{{ item.split('/')[1] }}</span>
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in fieldsToRender"
            :key="`${index}-${formKey}`"
            class="p-2"
          >
            <WfmControl
              :path="item?.path"
              :data="paramsToSend"
              :current-folder="folder"
              :field-info="item"
              :control-type="item.control_type"
            />
          </div>
          <div class="d-flex">
            <i>click on selected dates to apply for half day leaves
              <v-icon
                name="md-helpoutline"
                scale="1"
                :style="{ cursor: 'pointer' }"
              />
            </i>
            <v-tooltip
              class="mt-2"
              activator="parent"
              open-delay="500"
              close-delay="300"
              location="bottom"
            >
              <div class="d-flex">
                <div class="first_half_style duration-div me-1" />
                <span>first half</span>
                <div class="second_half_style duration-div me-1 ms-1" />
                <span>second half</span>
                <div class="full_day_style duration-div me-1 ms-1" />
                <span class="ms-1">full Day</span>
              </div>
            </v-tooltip>
          </div>

          <div class="days-div row">
            <div
              v-for="(item, index) in daysSelected"
              :key="index"
              class="ms-2 mt-1 each-date my-auto col-sm-2"
              :class="changeDurationStyle(item)"
              :style="{ cursor: 'pointer' }"
              @click="changeDuration(item)"
            >
              <div class="my-auto d-flex">
                <div class="d-flex">
                  <div>{{ item.date }}</div>
                  <div>{{ item.monthName }}</div>
                </div>
                <div>
                  <v-icon
                    v-if="item.duration == 'deselect_day'"
                    name="fa-ban"
                    scale="0.8"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- footer section modal -->
      <template #footer>
        <div class="footer-content d-flex justify-content-between ">
          <div>
            <WfmButton
              button-class="ms-1 save-btn me-2"
              data-test-id="leaves-save-btn"
              :disabled="savingForm"
              @button-click="processForm"
            >
              {{ currentTaskName == 'create' ? 'Apply leave' : 'Update leave' }}
            </WfmButton>
          </div>
        </div>
      </template>
    </WfmModal>
    <WfmLoader v-if="loadingLeaves" />
  </div>
  <LeavesFooter
    :leaves-quota="leavesQuota"
    :multi-select="multiselect"
    :selected-events="selectedEvents"
    :date-to-show="currentViewDate"
  />
</template>

<script>
import { ref, computed, onMounted, provide, watch, onUnmounted, onActivated } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import WfmControl from '../../../common/form-controls/wfm-control.vue'
import WfmButton from '../../../common/wfm-button.vue'
import { keys } from '../../../provideKeys'
import WfmLoader from '../../../common/loader/wfm-loader.vue'
import { useStore } from 'vuex'
import postData from '../../../composables/postData'
import getFatRow from '../../../composables/fatRowGenerator'
import leavesCreateUtility from '../../../composables/leavesCreateUtility'
import processFormData from '../../../composables/processFormData'
import { v4 as uuidv4 } from 'uuid';
import getFormInfo from '../../../composables/getFormInfo'
import makeTxn from '../../../composables/makeTxn'
import { sendMessage } from '../../../services/websocket'
import getConverters from '../../../composables/getConverters'
import { toast } from 'vue3-toastify'
import WfmModal from '../../../common/wfm-modal.vue'
import leavesForm from '../../../composables/leavesForms'
import CustomHeaderCalendar from '../../quick-actions/Leaves/CustomHeaderCalendar.vue'
import foldersUtility from '../../../composables/foldersUtility'
import makeTxnForBulk from '../../../composables/makeTxnForBulk'
import makeFilter from '../../../composables/makeFilter'
import EventCellRenderer from '../Leaves/EventCellRenderer.vue'
import LeavesFooter from '../Leaves/LeavesFooter.vue'
import getProjections from '../../../composables/getProjections'
import useLeaveDelete from '../../../composables/useLeaveDelete';
import processExpr from '../../../composables/processExprs';
export default {
  name: 'LeavesCalendar',
  components: {
    FullCalendar,
    WfmControl,
    WfmButton,
    CustomHeaderCalendar,
    WfmModal,
    EventCellRenderer,
    LeavesFooter,
    WfmLoader
  },
  props: {
    folder: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    agGridApi: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const showForm = ref(false);
    const leaveCalendarRef = ref(null);
    const calendarApi = ref(null);
    const notificationId = ref()
    const paramsToSend = ref()
    const formData = ref({})
    const deleteForm = ref(false)
    provide(keys.formData, formData)
    const userParams = ref()
    const currentTaskName = ref()
    const loadingLeaves = ref(false)
    const selectionInfo = ref()
    const selectedDates = ref([])
    const calendarKey = ref(0)
    const readOnlyMode = ref(false)
    const multiselect = ref(false)
    const store = useStore()
    const sessionId = store.getters['sessionIdGetter'];
    const bSettings = store.getters['bSettings'];
    const events = ref([]);
    const currentViewDate = ref()
    const savingForm = ref(false)
    const leavesQuota = ref()
    const filterParams = ref([])
    const selectedEvents = ref([])
    const isBulkTxn = ref(false)
    const invalidFields = ref([])
    provide(keys.invalidFields, invalidFields)
    const mandatoryFields = ref([])
    provide(keys.mandatoryFields, mandatoryFields)
    const daysSelected = ref([])
    provide(keys.leavesQuota, leavesQuota)
    const halfDayDurations = ref([])
    const { getLeavesForm } = leavesForm()
    const { SQLTsRangeToArray, arrayToSQLRange } = getConverters();
    const { processLeavesList, makeLeaveEvents } = leavesCreateUtility()
    const { fatRowGenerator } = getFatRow();
    const fieldsToUse = computed(() => {
      return getLeavesForm(currentTaskName.value)
    })

    const fieldsToRender = computed(() => {
      return fieldsToUse.value.map((field) => {
        const dataToPass = currentTaskName.value == 'create' ? formData.value : paramsToSend.value
        return {
          ...field,
          isVisible: checkVisible(field, dataToPass),
        };
      }).filter((field) => field.isVisible);
    });


    function checkVisible(field, data) {
      const { processVisibleExpr } = processExpr(props.folder);
      if (field.visible_expr) {
        return processVisibleExpr(field.visible_expr, data);
      }
      return true;
    }

    const formSaveClicked = ref(false)
    const { projectionForFolder } = getProjections()
    const userCode = computed(() => {
      const codeToUse = props?.data?.employees_id?.[0]?.code || formData.value['employees_id']?.value[0]?.code || userParams.value?.code
      return codeToUse != 'admin' ? codeToUse : null
    })
    const userId = computed(() => {
      const idToUse = props?.data?.employees_id?.[0]?.id || formData.value['employees_id']?.value[0]?.id || userParams.value?.id
      return idToUse != 'admin' ? idToUse : null
    })

    watch(userCode, (newValue) => {
      try {
        loadingLeaves.value = true
        getLeavesList(newValue)
        // const userId = userParams.value.id
        getLeavesQuota(userId.value)

      } catch (error) {
        console.log(error)
      }
    });

    const filterFields = computed(() => {
      const ret = [
        {
          'type': 'field',
          'path': 'leaves.leave_days.leave_status',
          'readonly_expr': "env.code!=='admin'",
          'default_value': 'created',
          'normal_control': true,
          'name': 'leave_status',
          'fieldOnlyView': true,
          'is_detail': true
        },
      ];
      return ret;
    })
    const initialDateToShow = computed(() => {
      if (props.data?.leave_days.length > 0) {
        const leavePeriod = props.data.leave_days?.[0]?.leave_period
        const selectedDateRangeArray = SQLTsRangeToArray(leavePeriod, 'UTC', true);
        return selectedDateRangeArray[0]
      }
      else {
        return new Date()
      }
    })
    const missingFields = computed(() => {
      if (!formSaveClicked.value) return [];

      const taskName = currentTaskName.value;
      const formDataObj = formData.value
      const mandatoryFieldsInForm = mandatoryFields.value;

      if (taskName === 'create' && formDataObj) {
        return mandatoryFieldsInForm.filter((field) => {
          const fieldKey = field.split('/')[1];
          return !formDataObj.hasOwnProperty(fieldKey);
        });
      }

      return [];
    });
    const calendarOptions = computed(() => {
      return {
        selectable: true,
        selectMirror: false,
        aspectRatio: 'auto',
        unselectAuto: true,
        height: 'auto',
        slotEventOverlap: false,
        contentHeight: 'auto',
        selectAllow: selectAllow,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        initialDate: initialDateToShow.value,
        headerToolbar: false,
        events: events.value,
        eventInteractive: true,
        // eventContent:eventContent,
        eventClick: eventClickHandle,
        select: handleDateClick
        // select:handleSelection
      }
    });


    // function checkVisible(item) {
    //   const { processVisibleExpr } = processExpr(props.folder, formData.value);
    //   let visibility = true;
    //   if (item.visible_expr != null) {
    //     visibility = processVisibleExpr(item.visible_expr)
    //   }

    //   return visibility
    // }
    function scrollToTheField(fieldName) {
      var targetDiv = document.getElementById(fieldName);

      if (targetDiv) {
        // Scroll to the div
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        targetDiv.focus()
      } else {
        console.error("Div with keyword '" + fieldName + "' not found.");
      }
    }

    function setSelectedDates() {
      const startDate = selectedDates.value[0].start;

      for (let i = 0; i < selectedDates.value[0].numberOfDays; i++) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        const month = new Date(date).toLocaleString('default', { month: 'short' });
        const weekDay = date.getDay();
        const eventExists = events.value.some((each) => {
          const eventDate = new Date(each.start);
          const normalizedEventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
          const normalizedSelectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          if (normalizedEventDate.getTime() === normalizedSelectedDate.getTime()) {
            const lCode = each.leave_status?.[0]?.code
            return !['rejected', 'cancelled'].includes(lCode)
          }
        })
        if (!eventExists) {
          daysSelected.value.push({
            date: date.getDate(),
            duration: 'full_day',
            monthName: month,
            fullDate: date,
            weekday: weekDay
          });

        }
      }
    }

    function handleInputSelect(arg) {
      if (selectedEvents.value && !selectedEvents.value.includes(arg)) {
        selectedEvents.value.push(arg)
      }
      else {
        const elementFound = selectedEvents.value.findIndex((eachSelected) => arg.event._def.publicId == eachSelected.event._def.publicId)
        delete selectedEvents.value[elementFound];
        selectedEvents.value = selectedEvents.value.filter((each) => each != null)
      }
    }
    function getSelectedEvents() {
      const tsRange = selectionInfo.value.event._def.extendedProps.leave_period
      const selectedDateRangeArray = SQLTsRangeToArray(tsRange, 'UTC', true);
      const leaveMinutes = selectionInfo.value.event._def.extendedProps.leave_minutes
      const halfDayDuration = selectionInfo.value.event._def.extendedProps.half_day_duration?.[0]?.code

      let durationType = null
      if (halfDayDuration != null && leaveMinutes == 240) {
        durationType = halfDayDuration
      }
      else {
        if (leaveMinutes == 240) {
          durationType = 'first_half'
        }
        else {
          durationType = 'full_day'
        }
      }
      // TODO:- this will be decided later on by its own flag
      // const durationType = leaveMinutes == 480 ? 'full_day' : 'first_half'
      const month = selectedDateRangeArray[0].toLocaleString('default', { month: 'short' })
      daysSelected.value.push({ date: selectedDateRangeArray[0].getDate(), duration: durationType, monthName: month })

    }
    function changeDurationStyle(item) {
      const isWeekend = [0, 6].includes(item.weekday)
      let retVal = isWeekend ? 'weekend_day ' : ''
      if (item.duration == 'full_day') {
        retVal += 'full_day_style'
      }
      else if (item.duration == 'first_half') {
        retVal += 'first_half_style'
      }
      else if (item.duration == 'second_half') {
        retVal += 'second_half_style'
      }
      else if (item.duration == 'deselect_day') {
        retVal += 'deselect_day'
      }
      return retVal

    }
    function changeDuration(item) {
      //on each click the docp value should be set and changed
      if (item.duration == 'full_day') {
        item.duration = 'first_half'
        item.durationType = setHalfDayDuration('first_half')
        return
      }
      else if (item.duration == 'first_half') {
        item.duration = 'second_half'
        item.durationType = setHalfDayDuration('second_half')
        return
      }
      else if (item.duration == 'second_half') {
        if (currentTaskName.value == 'create') {
          item.duration = 'deselect_day'
          item.durationType = setHalfDayDuration('deselect_day')
          return
        }
        else {
          item.duration = 'full_day'
          item.durationType = setHalfDayDuration('full_day')
          return
        }
      }
      else if (item.duration == 'deselect_day' && currentTaskName.value == 'create') {
        item.duration = 'full_day'
        item.durationType = setHalfDayDuration('full_day')
        return
      }
    }
    function setHalfDayDuration(name) {
      // const propertyName = 'half_day_duration'
      let retVal = name
      for (let i = 0; i < halfDayDurations.value.length; i++) {
        if (name === halfDayDurations.value?.[i]?.code) {
          retVal = [{
            'id': halfDayDurations.value?.[i]?.id,
            'deleted': false,
            'docpType': true,
            'detailType': true,
            'isMultipleDocp': false
          }]
        }
      }
      return retVal
    }
    function getFilters(filters) {
      filterParams.value = filters
      getLeavesList()
    }
    function closeModal() {
      showForm.value = false
      selectedDates.value = []
      daysSelected.value = []
      paramsToSend.value = {}
      formSaveClicked.value = false
      missingFields.value = []
      selectedEvents.value = []
      deleteForm.value = false
      multiselect.value = false

    }
    function deleteLeave(arg) {
      deleteForm.value = true
      currentTaskName.value = 'update'
      formData.value['deleted'] = true
      selectionInfo.value = arg
      selectionInfo.value.deleted = true

      //handle delete
      processForm()
    }
    async function makeParamsToSend(txn, bulkTxnName) {
      const selectedRows = selectedEvents.value
      if (selectedRows.length > 0) {
        notificationId.value = toast.loading('working on it...', {
          position: toast.POSITION.TOP_CENTER
        })
        const txnName = bulkTxnName
        const dataToChange = { path: '', value: [] }


        let docpSelected = [];
        let propertyName = null
        let docpSource = null
        const { changeWorkingFolder } = foldersUtility()
        let docpSourceFolder = null

        propertyName = 'leave_status'
        docpSource = props.folder.fields['leave_days'].fields[propertyName]?.source;
        docpSourceFolder = changeWorkingFolder(docpSource, bSettings?.output.data.records[0])

        //getting the docpId
        const serchTermToSend = txnName == 'Approve' ? 'Final Approved' : txnName
        const { generateFilterParams } = makeFilter(serchTermToSend, docpSourceFolder, null, null, true);
        const filterParams = generateFilterParams();
        const txnParams = { filters: filterParams, getRowCount: true, refRows: true };
        const { listData } = postData()
        const list = await listData(docpSourceFolder, txnParams);

        if (list.output.type === 'error') {
          console.log(list.output.message);
        } else {
          if (list.output.data.records.length > 0) {
            docpSelected = fatRowGenerator(list);
          }
        }
        //loop thru each selected row to create formData
        for (var i = 0; i < selectedRows.length; i++) {
          if (props.folder.name === 'leaves') {
            dataToChange.path = 'leaves.leave_days.leave_status'
            const dataParams = selectedRows[i].event._def.extendedProps
            if (txnName === 'Delete') {
              dataToChange.value.push({ oldDocpId: dataParams.leave_status?.[0].id, newId: docpSelected[0]?.id, txnOp: 'U', mainFolderId: dataParams.idToUse, propertyName: propertyName, params: dataParams, newDocp: docpSelected[0], deleteTxn: true })
            }
            else {
              if (dataParams.leave_status?.[0].id) {
                dataToChange.value.push({ oldDocpId: dataParams.leave_status?.[0].id, newId: docpSelected[0]?.id, txnOp: 'U', mainFolderId: dataParams.idToUse, propertyName: propertyName, params: dataParams, newDocp: docpSelected[0] })
              }
              else {
                dataToChange.value.push({ oldDocpId: null, newId: docpSelected[0]?.id, txnOp: 'C', mainFolderId: dataParams.idToUse, propertyName: propertyName, params: dataParams, newDocp: docpSelected[0] })
              }
            }

          }
        }
        const formData = dataToChange
        //generate the txnToRun with txn and fieldsData
        const { generateTxn } = makeTxnForBulk(
          formData,
          props.folder,
          txn,
          txnName
        )
        isBulkTxn.value = true
        const txnToRun = generateTxn()
        txnToRun.session_key = sessionId;
        // closeModal()
        const result = await sendMessage(txnToRun)
        await handleResult(result)
        //}


      }
      else {
        toast.warning('please select one or more records')
      }

    }
    async function handleDateClick(info) {
      //when clicking on empty date
      //opening form to create an event on clicking on date
      if (userCode.value !== null && !readOnlyMode.value && !multiselect.value && !deleteForm.value && !savingForm.value) {
        const { start, end } = info
        multiselect.value = false
        selectedEvents.value = []
        const selectedDate = { start: start, end: end };
        if (selectedDates.value.some((date) => date.start === start && date.end === end)) {
          selectedDates.value = selectedDates.value.filter((date) => date.start !== start || date.end !== end);
          events.value = events.value.filter((event) => event.start !== start || event.end !== end)
        } else {
          const starting = start
          const ending = end
          const numberOfDays = (ending - starting) / 8.64e+7
          selectedDate.numberOfDays = numberOfDays
          selectedDates.value.push(selectedDate);
          // Check if any event exists on the clicked date
          const eventExists = events.value.some((each) => {
            if (each.leave_period != null) {
              const eventDates = SQLTsRangeToArray(each.leave_period, 'UTC', true);
              const eventStartDate = eventDates[0]
              const selectedStartDate = start
              if (eventStartDate.getTime() == selectedStartDate.getTime()) {
                const lCode = each.leave_status?.[0]?.code
                return !['rejected', 'cancelled'].includes(lCode)
              }
            }

          })
          if (!eventExists) {
            showForm.value = true;
            currentTaskName.value = 'create'
            selectionInfo.value = info
            setSelectedDates()
            getHalfDayDurationList()
          }
          else {
            selectedDates.value = []
          }

        }
      }
      else {
        return false
      }


    }
    async function eventClickHandle(info) {
      //when clicking on events
      if (!deleteForm.value && !multiselect.value && selectedEvents.value.length == 0 && !savingForm.value) {
        selectionInfo.value = info
        const leavesData = info.event._def.extendedProps
        //get all the leaves data and send to form to show filled data
        //on click save should update the leaves data
        const leaveStatus = leavesData.leave_status?.[0]?.code
        if (!['rejected', 'cancelled'].includes(leaveStatus)) {
          if (leaveStatus == 'created') {
            paramsToSend.value = { ...leavesData }
            currentTaskName.value = 'update'
            showForm.value = true
            getSelectedEvents()
          }
          else {
            toast.warning(`${leaveStatus} leave is not editable`, {
              autoClose: 1000,
              closeButton: true,
              type: 'warning',
              position: toast.POSITION.TOP_CENTER,
            })
          }

        }
        else {
          toast.warning(`${leaveStatus} leave is not editable`, {
            autoClose: 1000,
            closeButton: true,
            type: 'warning',
            position: toast.POSITION.TOP_CENTER,
          })

        }
        getHalfDayDurationList()

      }


    }
    async function getHalfDayDurationList() {
      const docpSource = {
        'swhandle': '.',
        'container': 'business',
        'folder': 'half_day_duration',
        'foldertype': 'masters',
        'schema': 'ddnews_masters'
      }
      const { changeWorkingFolder } = foldersUtility();
      const newCurrentFolder = changeWorkingFolder(
        docpSource,
        bSettings.output.data.records[0]
      );
      const { listData } = postData()
      const txnParams = {}
      txnParams.refRows = true
      const list = await listData(newCurrentFolder, txnParams)
      const { fatRowGenerator } = getFatRow();
      halfDayDurations.value = fatRowGenerator(list)
    }
    function selectAllow() {
      return true
    }
    async function getLeavesQuota(empId) {
      try {
        const currentEmp = empId
        //provide the empId gets the leave quota
        //TODOsend the timestamp too
        const txnToGetLeaveQuota = props?.folder?.txns?.txn_show_leaves_quota
        const txnParams = { 'refRows': true, 'employees_id': currentEmp, 'input_date': currentViewDate.value }
        if (txnToGetLeaveQuota !== null && txnToGetLeaveQuota !== undefined) {
          txnToGetLeaveQuota.params = txnParams
          txnToGetLeaveQuota.session_key = sessionId;
        }
        const result = await sendMessage(txnToGetLeaveQuota)
        if (result?.output?.data?.records?.[0]) {
          leavesQuota.value = JSON.parse(result.output.data?.records?.[0])
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    function makeEventDateTotsRange(sDate) {
      const startDate = sDate
      const endDate = sDate
      // endDate = endDate.setDate(endDate.getDate() + 1)
      const leavePeriodArray = [startDate, endDate]
      const drefData = JSON.parse(JSON.stringify(leavePeriodArray)).map((dt) => new Date(dt));
      const sqlRangeStr = arrayToSQLRange(drefData, true, false, true);
      return sqlRangeStr
    }
    function getLeaveMinutes(index) {
      //first half/ second half / full day
      //TODO:-firsthalf and second half work to be when backend is done
      const dayLeaveDuration = daysSelected?.value?.[index]?.duration
      if (dayLeaveDuration == 'full_day') {
        return 480
      }
      else if (dayLeaveDuration == 'first_half') {
        return 240
      }
      else if (dayLeaveDuration == 'second_half') {
        return 240
      }
    }
    async function getLeavesList(empCode) {
      const { listData } = postData()
      const { generateFilterParams } = makeFilter(
        `${userCode.value}`,
        props.folder,
        null,
        'leaves.employees_id.code'
      )
      const empFilter = generateFilterParams()
      const txnParams = { filters: empFilter, getRowCount: true, refRows: true };
      const orderBy = {
        path: 'leaves.creation_date',
        value: 'asc'
      }
      txnParams.projections = projectionForFolder(props.folder.name)
      txnParams.refRows = true
      txnParams.page = {
        orderByBag: orderBy
      }
      //if admin is using , don't send filter with list view
      //if any other user send filter params to fetch employees leaves list
      const leavesTxn = await listData(props.folder, txnParams)
      if (leavesTxn.output.type === 'error') {
        console.log(leavesTxn.output.message);
      } else {
        if (leavesTxn.output.data.records.length > 0) {
          const { fatRowGenerator } = getFatRow();
          let leavesList = fatRowGenerator(leavesTxn);
          if (props.data) {
            leavesList = leavesList.filter((each) => each.id == props.data.id)
          }
          const processedList = processLeavesList(leavesList)
          const eventsData = makeLeaveEvents(processedList)
          eventsData[userCode.value]?.forEach((element) => {
            element.borderColor = 'white'
            element.textColor = 'black'
          })
          loadingLeaves.value = false
          events.value = eventsData[userCode.value] || []

        }
      }

    }
    async function processForm() {
      formSaveClicked.value = true
      const documents = formData.value?.['documents'];
      const leave_types_id = formData.value?.['leave_types_id'];
      const leave_status = formData.value?.['leave_status'];
      const deleted = formData.value?.['deleted']
      const notes = formData.value?.['notes']
      const label = leave_types_id?.[0]?.code;
      const eventFormData = {};
      if (missingFields?.value.length > 0 && currentTaskName.value === 'create') {
        toast.error('please check all the fields')
      }
      else if (invalidFields.value.length > 0) {
        toast.error('please fill all the fields')
      }
      else {
        // Add properties to eventFormData only if they are not null or undefined
        if (documents != null) eventFormData.documents = documents;
        if (leave_types_id != null) eventFormData.leave_types_id = leave_types_id;
        if (leave_status != null) eventFormData.leave_status = leave_status;
        if (notes != null) eventFormData.notes = notes;
        if (deleted != null) eventFormData.deleted = deleted;

        if (currentTaskName.value === 'create') {
          events.value.push({
            id: uuidv4(),
            start: selectionInfo.value.startStr,
            end: selectionInfo.value.endStr,
            label: label,
            startDate: selectionInfo.value?.startStr,
            formData: eventFormData
          });
        } else if (currentTaskName.value === 'update') {
          const updatedLabel = leave_types_id?.[0].code;
          events.value = events.value.map((event) => {
            if (event.idToUse === selectionInfo.value?.event?._def?.extendedProps?.idToUse) {
              return {
                ...event,
                start: event.start,
                end: event.end,
                deleted: selectionInfo.value.deleted,
                label: updatedLabel,
                backgroundColor: '#2f688d',
                formData: eventFormData
              };
            } else {
              return event;
            }
          });
        }
        await processLeave()
      }

    }
    async function processLeave() {
      showForm.value = false;
      const isUpdate = currentTaskName.value == 'update' ? true : false
      const notificationMsg = isUpdate && !deleteForm.value ? 'Updating...' : deleteForm.value ? 'Deleting..' : 'Saving...'
      savingForm.value = true
      notificationId.value = toast.loading(notificationMsg, {
        position: toast.POSITION.TOP_CENTER
      });

      // Loop through each event and prepare data
      const newEvents = events.value.filter((each) => each.formData != null)
      const leaveData = []
      newEvents.map((each) => {
        if (!isUpdate) {
          // const numberOfDays = selectedDates.value[0].numberOfDays
          for (var i = 0; i < daysSelected.value.length; i++) {
            const id = uuidv4()
            const newRowAdded = currentTaskName.value === 'create';
            // const leave_minutes = each.formData.leave_minutes
            const leave_minutes = getLeaveMinutes(i)
            const leave_status = each.formData.leave_status
            const documents = each.formData.documents
            const leave_types_id = each.formData.leave_types_id
            const half_day_duration = daysSelected.value[i].durationType
            if (half_day_duration != 'deselect_day') {
              if (half_day_duration != null) {
                half_day_duration[0].docpNotSet = true
              }
              if (daysSelected.value[i].fullDate != null) {
                const startDate = daysSelected.value[i].fullDate
                const leave_period = makeEventDateTotsRange(startDate);
                leaveData.push({ id, newRowAdded, leave_minutes, leave_status, leave_types_id, leave_period, documents, half_day_duration })
              }
            }
          }
        }
        else {
          const id = each.idToUse;
          // const leave_minutes = each.formData.leave_minutes
          const leave_minutes = getLeaveMinutes(0)
          const leave_status = each.formData.leave_status
          const leave_types_id = each.formData.leave_types_id
          let half_day_duration = null
          if (each.half_day_duration != null && each.half_day_duration.length != 0) {
            //docp already set
            half_day_duration = daysSelected.value?.[0]?.duration != 'full_day' ? daysSelected.value?.[0]?.durationType : null
            if (half_day_duration != 'deselect_day' && half_day_duration != 'full_day') {
              if (half_day_duration != null) {
                half_day_duration[0].docpNotSet = false
                half_day_duration[0].oldDocpId = each.half_day_duration?.[0]?.id
              }
            }


          }
          else {
            //new docp create
            half_day_duration = daysSelected.value?.[0]?.durationType
            if (half_day_duration != 'deselect_day') {
              if (half_day_duration != null) {
                half_day_duration[0].docpNotSet = true

              }
            }

          }

          const documents = each.formData.documents
          const deleted = each.formData.deleted
          const leaves_id = each.leaves_id
          leaveData.push({ id, leave_minutes, leave_status, leave_types_id, leaves_id, documents, deleted, half_day_duration });
        }
      })
      leaveData.pathofDetail = 'leaves.leave_days';
      leaveData.primaryKey = isUpdate ? leaveData[0].leaves_id : uuidv4();
      leaveData.primaryKeyField = 'leaves_id';
      leaveData.visible = true;
      const dataToSend = {
        leave_days: leaveData,
        notes:formData.value.notes,
        ...(isUpdate ? {} : { employees_id: formData.value.employees_id})
      };
      const { getGroupedFormData } = processFormData();
      const {
        normalFieldList,
        docpList,
        detailList,
        detailKeyFieldName
      } = getGroupedFormData(dataToSend, props.folder);
      const { getCurrentTxn } = getFormInfo();
      const txnToUse = getCurrentTxn(currentTaskName.value, props.folder);
      const params = {
        normalFieldList,
        docpList,
        detailList,
        folderDetails: props.folder,
        currentTaskName: currentTaskName.value,
        detailKeyName: detailKeyFieldName,
        txnType: currentTaskName.value,
        currentFolderId: leaveData.primaryKey,
        txn: txnToUse
      };
      // Generate the txnToRun with txn and fieldsData
      const { generateTxn } = makeTxn(params);
      let txnToRun = generateTxn();
      txnToRun.session_key = sessionId;

      if (deleteForm.value) {
        const {getLeaveDeleteTxn} = useLeaveDelete(props.folder)
        const deleteTxn = getLeaveDeleteTxn()
        const paramsToUse = txnToRun.params
        deleteTxn.params = paramsToUse
        txnToRun = deleteTxn
      }
      const response = await sendMessage(txnToRun);
      await handleResult(response)


    }
    async function handleResult(response) {
      const isUpdate = currentTaskName.value == 'update' ? true : false
      const notificationMsg = isUpdate && !deleteForm.value ? 'Update applied...' : deleteForm.value ? 'Deleted' : 'Saved'
      if (response.output.type === 'success') {
        toast.update(notificationId.value, {
          render: notificationMsg,
          autoClose: 100,
          closeButton: true,
          type: 'success',
          isLoading: false
        });
        if (Object.keys(response.output.data.records).length !== 0) {
          const records = [];
          for (let i = 0; i < response.output.data.records.length; i++) {
            records.push(JSON.parse(response.output.data.records[i]))
          }
          response.output.data.records = records;
          const listData = fatRowGenerator(response)
          const { leavesListToShow } = leavesCreateUtility()
          const processedList = leavesListToShow(listData)
          if (deleteForm.value) {
            props.agGridApi?.refreshCells({ force: true });
          }
          else {
            props.agGridApi?.applyTransaction({
              add: [processedList[0]],
              addIndex: 0
            })
          }

        }

      } else {
        toast.update(notificationId.value, {
          render: response.output.message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
          closeButton: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
      for (var key in formData.value) {
        if (key != 'employees_id') {
          delete formData.value[key];
        }
      }
      closeModal()
      savingForm.value = false
      await getLeavesList()
      getLeavesQuota(userId.value)
    }
    function handleKeyDown(event) {
      if (event.key === 'Control') {
        toggleControl();

      }
    }
    function toggleControl() {
      multiselect.value = !multiselect.value;
      if (multiselect.value == false) {
        selectedEvents.value = []
      }

    }
    function changeLeaveQuota(datePassed) {
      //get leaves quota with new date
      currentViewDate.value = datePassed
      getLeavesQuota(userId.value)
    }
    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown);
    });
    onActivated(() => {
      calendarApi.value.updateSize()
    });

    onMounted(() => {
      calendarApi.value = leaveCalendarRef.value?.getApi()
      userParams.value = bSettings?.env
      window.addEventListener('keydown', handleKeyDown);

      //if employees data passed map those events only
      if (props.data?.employees_id) {
        const employeeIdField = {
          value: props.data?.employees_id,
          fieldType: 'docpicker',
          path: 'leaves.employees_id',
          type: 'docpicker'
        };
        readOnlyMode.value = true
        employeeIdField.value[0].docpNotSet = true;
        formData.value['employees_id'] = employeeIdField;
        const processedList = processLeavesList([props.data])
        const eventsData = makeLeaveEvents(processedList)
        eventsData[userCode.value]?.forEach((element) => {
          element.borderColor = 'white'
          element.textColor = 'black'
        })
        events.value = eventsData[userCode.value] || []
      }

      if (userId.value) {
        getLeavesQuota(userId.value)
      }
    })
    return {
      calendarApi,
      calendarKey,
      leaveCalendarRef,
      calendarOptions,
      showForm,
      closeModal,
      fieldsToUse,
      paramsToSend,
      processForm,
      currentTaskName,
      leavesQuota,
      filterParams,
      filterFields,
      getFilters,
      multiselect,
      selectedEvents,
      makeParamsToSend,
      selectedDates,
      daysSelected,
      changeDuration,
      changeDurationStyle,
      initialDateToShow,
      missingFields,
      scrollToTheField,
      getHalfDayDurationList,
      userParams,
      handleInputSelect,
      deleteLeave,
      changeLeaveQuota,
      loadingLeaves,
      savingForm,
      readOnlyMode,
      currentViewDate,
      checkVisible,
      fieldsToRender
    };

  }
};
</script>
<style>
.deselect_day {
  background: rgb(98, 99, 99);
  color: white;

}

.duration-div {
  height: 20px;
  width: 30px;
}

.days-div {
  width: 500px;
}

.each-date {
  /* width: 30px; */
  height: 30px;
  border: 1px solid green;
  border-radius: 4px;
  padding: 3px;
}

.first_half_style {
  background: linear-gradient(90deg, rgb(144, 215, 245) 50%, #e3eef6 50%);

}

.full_day_style {
  background: rgb(144, 215, 245);
}

.weekend_day {
  border: 1.5px solid rgb(220 53 69);
}

.second_half_style {
  background: linear-gradient(90deg, #e3eef6 50%, rgb(144, 215, 245) 50%);

}

.deselect_date_style {
  background: rgb(69, 79, 83);
  color: grey;
  cursor: not-allowed;

}

.employees-dropdown {
  width: 300px;
  height: 20px;
  background-color: none;
}

.employees-dropdown .vs__dropdown-toggle {
  background-color: #2f688d;
  border: none;
  color: white;

}

.employees-dropdown .v-select {
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

.employees-dropdown .vs__selected {
  color: white;
}

.employees-dropdown .vs__open-indicator {
  color: white;
}

.employees-dropdown .close-class {
  color: white;
  fill: white;
}

.leaves-types-label {
  font-size: 20px;
}
</style>
<style scoped>
.status-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-box {
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.footer-content {
  background-color: white;
  padding: 8px;
}

.body-container {
  background-color: var(--main-background-color);
  height: 85% !important;
  overflow-y: scroll;
  padding: 20px;
  margin-top: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;

}

.body-container::-webkit-scrollbar {
  display: none !important;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-header {
  background-color: #f9fafc;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.list-body {
  text-align: left;
}

.list-header {
  text-align: left;
}

.containers {
  height: 82%;
  margin-top: 20px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* 5px rounded corners */
  max-height: 75%;
}

.table-container {
  height: 90%;
  overflow-y: scroll;
}

.table-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.form-box {
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.body-container {
  background-color: var(--main-background-color);
  height: 95%;
  overflow-y: scroll;
  padding: 20px;
}

.update-btn {
  color: #6faa5dff;
  color: white;
  border: 1px solid white;
  height: fit-content;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 20px;
  border-radius: 8px;
  box-shadow: 1px 1px #2e688dff;

}

.update-btn:hover {
  color: white;
  border: 1px solid white;
  background-color: var(--co-main-color);
}

.action-list {
  color: white;
  height: 30px;
  background-color: var(--toolbar-color);
}
</style>
