<template>
  <!-- if there is formObject -->
  <div
    v-if="formObjectFound"
    class="row fields-container"
  >
    <div
      v-for="(fieldGroup, groupIndex) in finalList"
      :key="groupIndex"
      :class="groupClass(fieldGroup)"
    >
      <div v-if="checkVisibleExpr(fieldGroup) && (fieldGroup.type === 'groups' || fieldGroup.type === 'group')">
        <!-- if the field type is group showing group tab with titles in it  -->
        <div
          class="header mt-2 p-3 d-flex justify-content-between"
          :data-test-id="makeDataTestId(fieldGroup)"
          @click="toggleFields(groupIndex)"
        >
          <div>
            {{ fieldGroup.name }}
            <span
              class="ms-2"
              style="color: red"
            >
              {{ fieldGroup.mandatory_expr? '*':'' }}
            </span>
          </div>
          <div>
            <v-icon
              fill="black"
              :name="iconName(fieldGroup)"
              scale="1.2"
            />
          </div>
        </div>
        <!-- expanded group  -->
        <div
          v-if="fieldGroup.expandGroup || showAllFields"
          ref="control"
          class="control-box row"
        >
          <!-- in each group show each fields  -->

          <div
            v-for="(field, fieldsIndex) in fieldGroup.fieldOptions"
            :key="fieldsIndex"
            :class="groupFieldsClass(field)"
          >
            <WfmControl
              :field-options="field"
              :data="params"
              :current-folder="currentFolder"
              :filled-data="filledData"
            />
          </div>
        </div>
      </div>
      <!-- if its not in group show the field -->
      <div
        v-else
        :class="fieldClass(fieldGroup)"
      >
        <!-- else show each field as it is  -->
        <WfmControl
          :field-options="fieldGroup.fieldOptions"
          :data="params"
          :current-folder="currentFolder"
          :filled-data="filledData"
        />
      </div>
    </div>
  </div>
  <!-- if no formObject found -->
  <div
    v-else
    class="row"
  >
    <div
      v-for="(items,index) in fieldsToDisplay"
      :key="index"
      class="col-sm-2"
    >
      <WfmControl
        :field-options="fieldOptions(items)"
        :data="params"
        :current-folder="currentFolder"
        :filled-data="filledData"
      />
    </div>
  </div>
</template>

<script>
import WfmControl from '../../common/form-controls/wfm-control.vue';
import { ref, provide, onMounted, inject } from 'vue';
import {keys} from '../../provideKeys.js'
import { useStore } from 'vuex';
import getFormFieldOptions from '../../composables/getFormFieldOptions';
import processExpr from '../../composables/processExprs';
import getFolders from '../../composables/getFolders';
// import getUtility from "../../composables/getUtility"
export default {
  name: 'WfmFieldGroup',
  components: { WfmControl },
  props: {
    params: { type: Object, default: null },
    formObjectFound: { type: Boolean, default: null },
    fieldsToDisplay:{type:Array, default:null},
    baseFolder: { type: Object, default: null },
    currentTask:{type:String, default:''},
    filledData:{type:Object, default:null}


  },
  setup(props, context) {
    // Variables
    const store = useStore();
    const showFields = ref(true)
    const control = ref();
    const finalList = ref([])
    const showAllFields = inject(keys.listExpanded, false)
    const currentFolder = ref(props.baseFolder);
    const {getFolderByName} = getFolders()
    const { processVisibleExpr, processMandatoryExpr } = processExpr(currentFolder.value);
    const toggleClicked = ref(false);
    const currentTaskName = props.currentTask
    provide(keys.currentTask, currentTaskName)
    const bSettings = store.getters['bSettings'];
    const { fieldsData } = getFormFieldOptions(props.fieldsToDisplay, currentFolder.value, bSettings?.output.data.records[0]);

    // const formData = computed(() => {
    //   return store.getters["commonModule/formData"];
    // });
    const formData = inject(keys.formData, null)
    function groupClass(fieldGroup) {
      const ret =  {'col-sm-3' : showInColumn(fieldGroup), 'd-none': fieldGroup.type === 'uuid' || !checkVisible(fieldGroup) }
      return ret
    }

    function groupFieldsClass(field) {
      const ret = {
        'col-sm-3 mb-2': field !== null && field?.type !== 'detail' && field.file_upload !== true,
        'd-none': checkVisibleExpr(field) == false,
        // 'col-sm-6':field?.exceptionDetail == true // TODO : temporary fix need to fix this properly
      }
      return ret
    }
    function fieldClass(fieldGroup) {
      const ret = { 'row mt-2': fieldGroup.fieldOptions?.type === 'detail' && fieldGroup.fieldOptions !== null }
      return ret
    }
    // Computed properties
    function iconName(fieldGroup) {
      return fieldGroup.expandGroup ? 'bi-caret-up' : 'bi-caret-down';
    }
    function fieldOptions(fieldGroup) {
      if (fieldGroup.fieldOptions != null) {
        return fieldGroup.fieldOptions
      }
      else {
        const type = fieldGroup.type;
        if (type === 'group') {
          return fieldGroup?.fields?.map((field) => getFieldOptions(field));
        } else {
          return getFieldOptions(fieldGroup);
        }
      }

    }
    function showInColumn(item) {
      // to render the controls nicely aligned in the form
      // checking if item is field type, group type if its coming from formObject
      // checking if item is detail or file type is there is no formObject defined and we are using the default folder fields
      // and deciding to show the control or table in newline or in a column (bootstrap grid) accordingly
      if (item.type == 'field') {
        const pathArray = item.path.split('.')
        if (pathArray.length < 3) {
          if (item.file_upload) {
            return false
          }
          else {
            return true
          }
        }
        const detailName = pathArray[pathArray.length - 2]
        if (currentFolder.value.fields[detailName]?.type === 'detail' || item.file_upload === true) {
          return false
        } else {
          return true
        }
      } else if (item.type === 'group') {
        return false
      }

    }
    function checkVisible(item) {

      let visibility = true
      if (item != null) {
        if (item?.visible_expr) {
          visibility = processVisibleExpr(item.visible_expr)
        }
        const itemType = ('type' in item) ? item.type : 'notype'
        const itemName = ('name' in item) ? item.name : 'noname'
        //on each field if the field is not visible but the data has been entered
        //flag is added. so that while making txn params.. we can decide to include the field or not
        if (itemType !== 'group' && formData.value[itemName] && itemName != 'id') {
          if (visibility) {
            formData.value[itemName].visible = true
          }
          else {
            formData.value[itemName].visible = false
          }
        }
        // store.dispatch("commonModule/formDataSetter", formData.value)

      }
      return visibility
    }
    // Methods
    function checkForMandatoryFields(fieldGroup) {
      if (fieldGroup.mandatory_expr == undefined) {
        //if the group doesn't have mandatory_expr defined
        //check in each field of the group if any of its fields are mandatory
        const arr = fieldGroup?.fields?.map((eachField) => {
          if (processMandatoryExpr(eachField.mandatory_expr)) {
            return true;
          } else {
            return false;
          }
        })
        if (arr?.includes(true)) {
          return true
        }
        else {
          return false
        }
      }
      else {
        //if the group has mandatory_expr defined on it
        //evaluate the expr and return the result
        const exprResult = processMandatoryExpr(fieldGroup.mandatory_expr);
        return  exprResult
      }
    }
    function getFieldOptions(field) {
      const mandatory_expr = field?.mandatory_expr || false;
      const is_multiple = field?.is_multiple || null;
      const visible_expr = field?.visible_expr !== null ? field.visible_expr : true;
      const readonly_expr = field?.readonly_expr || false;
      const default_value = field.default_value !== null ? field.default_value : null;
      const format_expr = field?.format_expr || null;
      const filters = field?.filters || null;
      const valid_expr = field?.valid_expr;
      const file_upload = field?.file_upload || null;
      const placeHolder = field?.placeholder || null
      const baseFolderName = field.path.split('.')[0]
      const baseFolder = getFolderByName(baseFolderName, bSettings)
      const params = {
        path:field?.path,
        currentFolder:baseFolder,
        bSettings:bSettings?.output.data.records[0],
        mandatory_expr:mandatory_expr,
        visible_expr:visible_expr,
        readonly_expr:readonly_expr,
        default_value:default_value,
        format_expr:format_expr,
        filters:filters,
        valid_expr:valid_expr,
        file_upload:file_upload,
        placeHolder:placeHolder,
        is_multiple:is_multiple
      }
      const fieldInfo = fieldsData(params);
      if (['mobiles', 'email_ids'].includes(fieldInfo?.name)) {
        fieldInfo.exceptionDetail = true
      }
      return fieldInfo ? fieldInfo : null;
    }

    function toggleFields(index) {
      // //changing the expandGroup flag on toggle click
      // finalList.value.forEach((each) => {
      //   if (fieldGroup.name === each.name) {
      //     each.expandGroup = !each.expandGroup
      //   }
      //   console.log("finalFields", fieldGroup)
      // })
      // finalList.value[index] = "expandGroup"
      finalList.value[index].expandGroup = !finalList.value[index].expandGroup
    }
    function makeDataTestId(fieldGroup) {
      return  `${props.baseFolder.name}/${fieldGroup.name}`
    }

    function checkVisibleExpr(data) {
      let test = true;
      if (data != null) {
        if (data?.visible_expr) {
          test = processVisibleExpr(data.visible_expr);
        }
        if (data.type !== 'group' && formData.value[data.name] && data.name != 'id') {
          if (test) {
            formData.value[data.name].visible = true;
          } else {
            formData.value[data.name].visible = false;
          }
        }
        // store.dispatch("commonModule/formDataSetter", formData.value);
      }
      return test;
    }
    function prepareFieldsToShow(list) {
      const retVal = list?.map((eachFieldGroup) => {
        const type = eachFieldGroup.type;
        if (type === 'group') {
          //getting folderOptions of each Fields which are in group
          const retVal = eachFieldGroup?.fields?.map((field) =>
          {
            if (field.fieldOptions != null) {
              return field.fieldOptions
            }
            else {

              if (field.path != null) {
                return getFieldOptions(field)
              }
              else {
                console.log('fields cant be found')
              }
            }
          });
          eachFieldGroup.fieldOptions = retVal
        }
        else {
          //getting folderOptions of Fields which are not in group
          // if (eachFieldGroup.fieldOptions == null && eachFieldGroup.path != "") {
          let retVal = null
          if (eachFieldGroup?.reportsFilter) {
            retVal = eachFieldGroup.fieldOptions != null ? eachFieldGroup.fieldOptions : getFieldOptions(eachFieldGroup);
          }
          else {
            retVal = getFieldOptions(eachFieldGroup)
          }
          if (retVal != null) {
            eachFieldGroup.fieldOptions = retVal
          }
        }
        //checking if the fieldGroup needs to be mandatory Field or not
        const mandatory = checkForMandatoryFields(eachFieldGroup)
        eachFieldGroup.mandatory_expr = mandatory
        eachFieldGroup.expandGroup = mandatory ? true : false
        return eachFieldGroup

      })
      return retVal
    }
    onMounted(() => {
      //on mounted prepare the finalList to show with all the needed flags fro ui
      finalList.value = prepareFieldsToShow(props.fieldsToDisplay)
    })

    return {
      toggleFields,
      showFields,
      iconName,
      control,
      checkForMandatoryFields,
      fieldOptions,
      getFieldOptions,
      toggleClicked,
      checkVisibleExpr,
      currentFolder,
      showInColumn,
      checkVisible,
      groupClass,
      fieldClass,
      showAllFields,
      finalList,
      makeDataTestId,
      groupFieldsClass
    };
  },
};
</script>

<style>
.mandatory {
  color: white !important;
}
.fields-container{
  background-color: var(--main-background-color);

}
.header {
  /* background-color: #edededff; */
  background-color: var(--field-group-header);
  color: black;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  border-left: 4px solid var(--left--border--color);
}

.control-box {
  border: 1px solid #d4d4d5;
  padding: 15px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-left: 4px solid var(--left--border--color);
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.row {
  align-items: flex-end;
}
</style>
